import { styled, keyframes } from 'styled-components';
import emailjs from '@emailjs/browser';
import {useRef, useState} from 'react';
import { Exit_B, dropdown } from '../assets';

const vibration = keyframes`
  from {
    transform : rotate(1deg);
  }
  to {
    transform : rotate(-1deg);
  }
`

const S = {
  GIT : styled.form`
    width : 100vw;
    height : 100vh;

    background : #000000cc;
    position : fixed;
    top : 0;
    left : 0;
    z-index : 5;
  `,
  Section : styled.section`
  background-color : white;
  height : max(375px, calc(75% - 64px));
  overflow : scroll;
  border-radius: 0 0 30px 30px;
  padding : 32px;
  font-family : 'Pretendard';
  text-align : start;
`,
Title : styled.div`
  font-family : 'GosanjaOTF';
  margin-bottom : 16px;
  font-size : 24px;
  display : flex;
  justify-content : space-between;
`,

Exit : styled.div`
  width : 16px;
  height : 16px;
  background : no-repeat center/contain url(${Exit_B});
  margin : auto 0;
`,

Subtitle : styled.div`
  font-size : 12px;
  margin-bottom : 5px;
  margin-top : 10px;
`,

Selectbox_M :styled.select`
  width : 100%;
  height : 51px;
  font-family : 'Pretendard';
  border : 1px solid #171717;
  font-size : 16px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background : url(${dropdown}) no-repeat 95% 50%;
  padding-left : 20px;
`,

Input : styled.input`
  width : 100%;
  height : 51px;
  border : 1px solid #171717;
  font-size : 16px;
  font-family : 'Pretendard';
  padding : 0 20px;
  
  &.wrong {
    border-color : #ff5757;
  }

  &:focus {
    outline : none;
  }
`,

WrongImg : styled.div`
  width : 50px;
  height : 51px;
  border : 1px solid #171717;
  border-left : none;
`,
InputGroup : styled.div`
  display : flex;

  & input {
    width : 100%;
  }
`,
Radio_group : styled.div`
  display : flex;
  justify-content : space-between;
`,
Radio : styled.input.attrs(pros => ({type : 'radio'}))`
  display : none;

  & ~ label {
    background-color : #f5f5f5;
    color : #999999;
    border : 1px solid #999999;
  }

  &:checked + label {
    background-color : #ffffff;
    color : #171717;
    border : 1px solid #171717;
    font-weight : 700;
  }
`,
Radio_label : styled.label`
  width : 47%;
  height : 51px;
  text-align : start;
  cursor : pointer;
  line-height : 51px;
  font-size : 16px;
  padding-left : 20px;
  box-sizing : border-box; 
`,
Message : styled.textarea`
  resize : none;
  width : calc(100% - 40px);
  height : 102px;
  flex-grow : 1;

  font-size : 16px;
  font-family : 'Pretendard';
  padding : 20px;
`,

Dropdown_title : styled.div`
  display : flex;
  justify-content : space-between;
  margin : 10px 0;
  font-size : 14px;
  line-height : 1.3em;
`,
Dropdown_icon : styled.div`
  width : 10px;
  height : 5px;
  background : no-repeat center/contain url(${dropdown});
  margin : auto 0;

  &.open {
    transform : rotate(180deg);
  }
`,
Dropdown_desc : styled.div`
  font-size : 12px;
  font-weight : 400;
  line-height : 1.3em;
  &.close {
    display : none;
  }
`,

CheckBoxWrapper : styled.label`
  display : flex;
  margin : 10px auto 20px;
  justify-content : center;
  width : 90%;

  &.vibration {
    animation : ${vibration} 0.1s;
    animation-iteration-count : 3;
  }
`,
CheckBoxInput : styled.input`
  appearance : none;
  width : 16px;
  height : 16px;
  border : 1px solid #ffffff;
  border-radius : 2px;
  background-clip : content-box;
  margin-right : 9px;

  &:checked {
    padding : 3px;
    border-radius : 1px;
    background-color : #ffffff;
  }
`,
CheckBoxLabel : styled.div`
  width : calc(100% - 40px);
  text-align : start;
  color : #ffffff;
  font-size : 14px;
  font-family : 'Pretendard';
  line-height : 1.3em;
  font-weight : 400;
`,
Button : styled.button.attrs(props => ({type : 'submit'}))`
  font-family : 'GosanjaOTF';
  width : 170px;
  height : 43px;
  border-radius : 22px;
  color : #121212;
  background-color : #ffffff;
  font-size : 16px;
`,
}

const GetInTouchM = ({closeModal}) => {

  const close = () => {
    closeModal();
  }

  const [mobile_isopendetail, setMobile_isopendetail] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const formRef = useRef()

  const submit = (e) => {
    e.preventDefault()
    const checkbtn = document.getElementById('checkinput');
    if (checkbtn.checked) {
      if(name !== "" && email !== "") {
        postContact(e);
      } else {
        alert("모든 항목을 입력해주세요.");
      }
    }
    else {
      const check = document.getElementById('check');
      check.classList.add("vibration");
      setTimeout(() => {
        check.classList.remove("vibration");
      }, 1000);
    }
  }

  const postContact = () => {
    emailjs.sendForm("service_soinfj4", "template_uwzv92f", formRef.current, "19LP0E-gjU_jO_c75" ).then(
      result => {
        alert("성공적으로 전송하였습니다.");
        formRef.current.reset();
        close()
      },
      error => {
        console.log(error.text);
        alert("전송이 실패되었습니다.")
      }
    )
  }

  return (
    <S.GIT onSubmit={submit} ref={formRef}>
      <S.Section>
        <S.Title>
          <span/>
          GET IN TOUCH
          <S.Exit onClick={() => close()}/>
        </S.Title>
        <S.Subtitle>Your name</S.Subtitle>
        <S.InputGroup>
          <S.Input id='name' name="from_name" onChange={(e) => setName(e.target.value)}/>
        </S.InputGroup>

        <S.Subtitle>E-mail</S.Subtitle>
        <S.InputGroup>
          <S.Input id='email' name="email" onChange={(e) => setEmail(e.target.value)}/>
        </S.InputGroup>

        <S.Subtitle>Product</S.Subtitle>
        <S.Radio_group>
          <S.Radio name="product" id="jikji" value="JIKJI" defaultChecked/><S.Radio_label for="jikji">JIKJI</S.Radio_label>
          <S.Radio name="product" id="cubi" value="CUBI"/><S.Radio_label for="cubi">CUBI</S.Radio_label>
        </S.Radio_group>

        <S.Subtitle>Message</S.Subtitle>
        <S.Message id='message' name="message"/>

        <S.Dropdown_title onClick={() => setMobile_isopendetail(!mobile_isopendetail)}>
          [개인정보 수집/이용 등에 관한 사항]
          <S.Dropdown_icon className={mobile_isopendetail ? 'open' : ''}/>
        </S.Dropdown_title>
        <S.Dropdown_desc className={mobile_isopendetail ? "open" : "close"}>
          아래 수집하는 개인정보는 이용자의 문의에 대한 답변을 위해 요구되는 정보로
          정보의 최대 보유 기간은 수집 후 1년입니다.
          문의 사항에 대한 답변을 받기를 원한다면
          해당 서비스를 이용시 동의를 하신 후 서비스를 이용하실 수 있습니다.
          <br/>
          - 필수 정보: 성명, 전화번호, 이메일
        </S.Dropdown_desc>
      </S.Section>

      <S.CheckBoxWrapper id='check' >
        <S.CheckBoxInput id='checkinput' type="checkbox"/>
        <S.CheckBoxLabel>
          [개인정보 수집/이용 등에 관한 사항]을 잘 읽어보았으며 내용에 동의합니다.
        </S.CheckBoxLabel>
      </S.CheckBoxWrapper>
      <S.Button>SUBMIT</S.Button>
    </S.GIT>
  )
}

export default GetInTouchM;