import {styled} from "styled-components";
import { BG2, BG_star } from "../assets";
import { useEffect, useState } from "react";
import { GetInTouchM } from "../components";

const S = {
  Sticky : styled.section`
    width : 100vw;
    height : calc(var(--vh, 1vh) * 400);
    position : relative;
  `,
  Home : styled.section`
    width : 100vw;
    height : calc(var(--vh, 1vh) * 100);
    background : no-repeat center/cover url(${BG2}), #171717;
    background-blend-mode: color-dodge;
    font-family : 'GosanjaOTF';

    position : sticky;
    top : 0;
    left : 0;
    bottom : 0;
    z-index : 1;

    display : flex;
    justify-content : space-around;
    align-items : center;
    flex-direction : column;

    overflow : hidden;
  `,
  Title : styled.h1`
    font-size : 52px;
    line-height : 120%;
    letter-spacing : -0.01em;
    margin : 10px 0 0;

    background-image: linear-gradient(90deg, #DBDBBC 0%, #798DAF 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  `,
  SubTitle : styled.h3`
    font-weight : 400;
    font-size : 16px;
    line-height : 150%;
    letter-spacing : -0.01em;
    margin : 0;
    color : #ffffff;
  `,
  Title_sec : styled.section`
    position : relative;
    z-index : 2;
    
  `,
  Get_Button : styled.button`
    width : 170px;
    height : 43px;
    border-radius : 22px;
    color : #121212;
    font-size : 16px;
    line-height : 120$;
    letter-spacing : -0.01em;
    font-family : 'GosanjaOTF';

    position : relative;
    z-index : 2;
  `,
  Text : styled.section`
    position : absolute;
    top : 10vh;
    left : 0;

    width : 100vw;
    height : 80vh;

  `,
  Text_item : styled.div`
    display : flex;
    align-items : end;
    position : absolute;
    text-align : start;

    &.first {
      top : 20vh;
      left : 10vw;
      flex-direction : row-reverse;
      text-align : end;
    }

    &.second {
      top : 65vh;
      left : 20vw;
      flex-direction : row-reverse;
      text-align : end;
    }

    &.third {
      top : 15vh;
      left : 65vw;
    }

    &.forth {
      top : 60vh;
      left : 70vw;
    }
  `,
  Text_title : styled.div`
    font-size : 50px;
    line-height : 150%;
    letter-spacing : -0.01em;
    color : #ffffff;
  `,
  Text_content : styled.div`
    font-size : 18px;
    line-height : 150%;
    color : #ffffff;
    font-family : 'Pretendard';
    margin : 0 22px 15px;
  `,
  Star : styled.img`
    width : 1688px;
    height : 652px;
    position : absolute;
    left : 30%;
    top : 50%;

    z-index : 1;
  `
}

const Main_M = () => {
  const [scroll, setScroll] = useState(0);
  const [isopen, setIsopen] = useState(false)

  useEffect(() => {
    if (isopen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isopen])

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      const newScroll = currentScrollY / (window.innerHeight * 3);
      setScroll(newScroll);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
  }, [scroll])

  const getPosition = () => {
    
    const position = [['100px', '320px'], ['-100px', '-150px'], ['-1200px', '450px'], ['-1400px', '-100px']]

    let idx = 0
    if(scroll < 0.2) {
      idx = 0
    } else if (scroll < 0.4) {
      idx = 1
    } else if (scroll < 0.6) {
      idx = 2
    } else if (scroll >= 0.6) {
      idx = 3
    }

    return {
        left : position[idx][0],
        top : position[idx][1],
        transition: 'left 0.5s ease-in-out, top 0.5s ease-in-out'
    };
  }

  const closemodal = () => {
    setIsopen(false)
  }

  return (
    <S.Sticky>
      <S.Home>
        <S.Star src={BG_star} style={getPosition()} alt="star"/>

        <S.Title_sec>
          <S.SubTitle>단순히 팔로워가 아닌<br/>새로운 이야기의 주인공</S.SubTitle>
          <S.Title>Stars<br/>call to us</S.Title>
        </S.Title_sec>

        <S.Get_Button onClick={() => setIsopen(!isopen)}>GET IN TOUCH</S.Get_Button>
      </S.Home>

      {isopen && <GetInTouchM closeModal={closemodal} />}
    </S.Sticky>
  )
}

export default Main_M;