import {styled} from 'styled-components';
import { Exit, dropdown, logo_mini } from "../assets";
import { useEffect, useRef, useState } from "react";
import data from './companydata.js';
import {default as data_sis} from './sisdata.js';

const S = {
  Modal : styled.section`
    width : 100vw;
    height : 100vh;
    position : fixed;
    top : 0;
    background-color: rgba(0,0,0,0.8);
    z-index : 998;
  `,
  Modal_cont : styled.section`
    width : 80vw;
    height : 90vh;
    z-index : 999;
    position : fixed;
    top : 50%;
    left : 50%;
    transform : translate(-50%, -50%);
    font-family : 'GosanjaOTF';
  `,
  Header : styled.section`
    padding-bottom : 20px;
    display : flex;
    justify-content : flex-end;
  `,
  Body : styled.div`
    display : flex;
    width: 100%;
    height : calc(100% - 50px);
    font-family : 'Pretendard';
  `,
  Content : styled.form`
    width : 100%;
    height : 100%;
    display : flex;
    background : #ffffff;
    box-sizing : border-box;
    border-radius : 32px;
    color : #000000;
    flex-direction : column;

  `,
  Exit : styled.div`
    background : no-repeat center/contain url(${Exit});
    width : 30px;
    height : 30px;
    cursor:pointer;
  `,
  Title_sec : styled.section`
    width : 100%;
    height : 97px;
    display : flex;
    align-items : center;
    padding : 30px 40px;
    box-sizing : border-box;

    font-family : 'GosanjaOTF';
  `,
  Title : styled.h1`
    font-size : 32px;
    margin : 0;
    font-weight : 400;
  `,
  Logo : styled.img`
    width : 36px;
    height : 30px;
    margin-right : 10px;
  `,
  Dropdown : styled.img`
    width : 28px;
    height : 14px;
    margin-left : 20px;
    cursor : pointer;
  `,
  Dp_List : styled.div`
    width : 325px;
    height : 246px;
    border : 1px solid #000000;
    background : #ffffff;
    border-radius : 12px;

    position : relative;
    top : 123px;
    padding : 0 0 0 30px;
    box-sizing : border-box;
    display : flex;
    flex-direction : column;
    justify-content : space-evenly;
    margin-left : 20px;
  `,
  Dp_item : styled.div`
    font-size : 20px;
    opacity : 20%;
    text-align : start;
    cursor : pointer;

    &.select {
      opacity : 100%;
    }
  `,
  Content_sec : styled.section`
    width : 100%;
    height : calc(100% - 97px);
    background : #f5f5f5;
    border-radius : 0 0 32px 32px;
  `,
  Tab_sec : styled.section`
    width : 100%;
    height : 73px;
    background : #ffffff;
    display : flex;
  `,
  Tab_item : styled.div`
    width : 20%;
    height : 100%;
    font-size : 18px;
    color : #c1c1c1;
    font-weight : 600;
    display : flex;
    align-items : center;
    justify-content : center;
    cursor : pointer;

    &.select {
      border-bottom : 2px solid #000000;
      color : #000000;
    }
  `,
  Article : styled.article`
    width : 100%;
    padding : 7vh 7vw;
    box-sizing : border-box;
    height : 100%;
    overflow-y : auto;
  `,
  Con_div : styled.div`
    &.right {
      display : flex;

      & div {
        margin-right : 60px;
      }
    }

    &.top {
      display : flex;
      flex-direction : column-reverse;
    }
  `,
  SIS : styled.section`
    width : 100%;
    height : calc(100% - 78px);
    overflow-y : auto ;
  `,
  Con : styled.section`
    width : 100%;
    margin-bottom : 130px;
    word-break : keep-all;
    &.right {
      display : flex;

      & div {
        margin-right : 60px;
      }
    }

    &.top {
      display : flex;
      flex-direction : column-reverse;
    }

    & div {
      margin-bottom : 50px;

      & .flex {
        display : flex;
        justify-content : space-between;
        & p {
          width : 45%;
        }
      }
    }
  `,
  Con_title : styled.h3`
    font-size : 30px;
    font-weight : 700;
    line-height : 41.5px;
    color : #222222;
    margin : 0;
    margin-bottom : 40px;
    text-align : start;

    &.two {
      text-align : center;
    }
    `,
  Con_con : styled.p`
    font-size : 18px;
    line-height : 32.5px;
    text-align : start;
    white-space : pre-wrap;
  `,
  
  Ov_sec : styled.section`
    display : flex;
    width : 100%;
  `,
  Ov_title : styled.h2`
    font-size : 30px;
    font-weight : 700;
    line-height : 41.5px;
    color : #222222;
    margin : 0;
    text-align : start;
  `,
  Ov_cont : styled.p`
    font-size : 18px;
    line-height : 32.5px;
    letter-spacing : -0.003em;
    text-align : start;
    word-break : keep-all;
    padding-right : 20px;
  `,
  Ov_img : styled.img`
    margin : auto;
    max-width : 305px;
    width : 50%;
  `,
  SIS_title : styled.h2`
    font-size : 30px;
    font-weight : 700;
    line-height : 41.5px;
    letter-spacing : -0.007em;
    color : #222222;
    margin : 124px 0 0;
  `,
  BN_img : styled.img`
    width : 100%;
    margin-bottom : 124px;
  `,
  Cate_sec : styled.section`
    display : flex;
    justify-content : space-evenly;
    margin-top : 40px;
  `,
  Cate_card : styled.div`
    min-height : 375px;
    border : 1px solid #c1c1c1;
    border-radius : 12px;
    padding : 24px 33px;
    box-sizing : border-box;

    &.three {
      width : 30%;
    }

    &.four {
      width : 23%;
    }
  `,
  Cate_title : styled.h5`
    font-size : 18px;
    color : #222222;
    font-weight : 600;
    width : 100%;
    margin : 0;
    text-align : start;
    line-height : 32.5px;
  `,
  Cate_subtitle : styled.p`
    font-size : 14px;
    font-weight : 700;
    line-height : 20.3px;
    color : #707273;
    text-align : start;
    margin : 9px 0;
  `,
  Cate_en : styled.h5`
    font-size : 15px;
    font-weight : 500;
    line-height : 21.5px;
    color : #c1c1c1;
    text-align : start;
    margin : 0;
  `,
  Cate_content : styled.p`
    font-size : 14px;
    color : #707273;
    line-height : 24.8px;
    text-align : start;
    word-break : keep-all;
    white-space : pre-wrap;
  `,
  Cate_img : styled.img`
    width : 100%;
    margin : 0 auto 14px;
  `,
  Capa_sec : styled.section`
  `,
  Capa_img : styled.img`
    width : 100%;
  `,
  Capa_conts : styled.div`
    display : flex;
    justify-content : center;
    margin-top : 20px;
  `,
  Capa_cont : styled.div`
    font-size : 18px;
    line-height : 32.5px;
    letter-spacing : -0.003em;
    color : #262c31;
    text-align : start;
    margin-right : 100px;
    white-space : pre-wrap;
  `,
  Etc_sec : styled.section`
    
  `,
  Etc_video : styled.iframe`
    width: 740px;
    height: 416px;
    allowfullscreen;
    margin-top : 23px;
  `
}
const CompanyModal = ({closeModal, type}) => {

  const [menuopen, setMenuopen] = useState(false);
  const [menu, setMenu] = useState(type)
  const [tab, setTab] = useState('SMART FACTORY') //SIS 탭 선택지
  const menulist = ['VIDEO INTELLIGENCE', 'DATA INTELLIGENCE', 'OPERATION INTELLIGENCE', 'DATA VISUALIZATION', 'SMART IoT SOLUTIONS']
  const tablist = ['SMART FACTORY', 'SMART IT Operations', 'SMART Service Operations', 'Smart City', 'Smart Asset Monitoring']

  const close = () => {
    closeModal();
  }

  const contentRef = useRef();
  const sisRef = useRef();

  useEffect(() => {
    contentRef?.current?.scrollTo({
      top : 0,
      behavior : 'smooth'
    })

    if (menu !== 'SMART IoT SOLUTIONS') {
      setTab('SMART FACTORY')
    }
  }, [menu])

  useEffect(() => {
    sisRef?.current?.scrollTo({
      top : 0,
      behavior : 'smooth'
    })
  }, [tab])

  return (
    <S.Modal>
      <S.Modal_cont>
        <S.Header>
          <S.Exit onClick={() => close()}/>
        </S.Header>

        <S.Body>
          <S.Content>
            <S.Title_sec>
              <S.Logo src={logo_mini} alt="logo"/>
              <S.Title>{menu}</S.Title>
              <S.Dropdown src={dropdown} onClick={() => setMenuopen(!menuopen)} alt="icon_dropdown"/>
              
              {menuopen &&
                <S.Dp_List>
                  {menulist.map((item, key) => (
                    <S.Dp_item className={item === menu ? 'select' : ''} key={key} onClick={() => {setMenu(item); setMenuopen(false)}}>{item}</S.Dp_item>
                  ))}
                </S.Dp_List>
              }
            </S.Title_sec>

            <S.Content_sec>
              {menu === 'SMART IoT SOLUTIONS' &&
                <S.Tab_sec>
                  {tablist.map((item, key) => (
                    <S.Tab_item className={tab === item ? 'select' : ''} key={key} onClick={() => setTab(item)}>{item}</S.Tab_item>
                  ))}
                </S.Tab_sec>
              }
              
              <S.Article ref={contentRef}>
                {/* SIS 제외 */}
                {data[menu]?.map((item, key) => (
                  <S.Con key={key}>
                    {typeof(item.title) === 'object' ?
                      <div style={{'display' : 'flex'}}>
                        {item.title.map((item2, key2) => (
                          <div key={key2} style={{'width' : '50%'}}>
                            <S.Con_title className="two">{item2}</S.Con_title>
                            <S.Con_con>{item.content[key2]}</S.Con_con>
                          </div>
                        ))}
                      </div>
                      :
                      <>
                        <S.Con_title>{item.title}</S.Con_title>
                        <S.Con_div className={item.img_loca}>
                          <div className={item?.column ? "flex" : ""}>
                            {item?.content?.map((item2, key2) => (
                              <S.Con_con key={key2}>{item2}</S.Con_con>
                            ))}
                          </div>
                        </S.Con_div>
                      </>
                    }
                  <img alt={item.title} src={item.img} style={{'width' : '100%'}}/>
                  </S.Con>
                ))}

                {/* SIS 탭 */}
                {menu === "SMART IoT SOLUTIONS" &&
                  <S.SIS ref={sisRef}>
                    {/* Overview */}
                    <S.Ov_title>Overview</S.Ov_title>
                    <S.Ov_sec>
                      <div style={{'width' : '50%'}}>
                        {data_sis[tab].overview.map((item, key) => (
                          <S.Ov_cont key={key}>{item}</S.Ov_cont>
                        ))}
                      </div>
                      <S.Ov_img src={data_sis[tab].overview_img} alt="overview"/> 
                    </S.Ov_sec>

                    {/* benefits */}
                    <S.SIS_title>SOLUTIONS BENEFITS</S.SIS_title>
                    {data_sis[tab]?.benefits.map((item, key) => (
                      <S.BN_img src={item} key={key} alt="benefits"/>
                    ))}

                    <S.SIS_title>적용분야</S.SIS_title>
                    <S.Cate_sec>
                      {data_sis[tab]?.category.map((item, key) => (
                        <S.Cate_card className={data_sis[tab]?.category.length === 3 ? "three" : "four"}>
                          {item?.img && <S.Cate_img src={item?.img} alt="content"/>}
                          <S.Cate_title>{item?.title}</S.Cate_title>
                          {item?.title_en && <S.Cate_en>{item.title_en}</S.Cate_en>}
                          {item?.subtitle && <S.Cate_subtitle>{item.subtitle}</S.Cate_subtitle>}
                          <S.Cate_content>{item?.content}</S.Cate_content>
                        </S.Cate_card>
                      ))}
                    </S.Cate_sec>

                    {data_sis[tab]?.capa_img &&
                      <S.Capa_sec>
                        <S.SIS_title>CAPABILITIES</S.SIS_title>
                        <S.Capa_conts>
                          {data_sis[tab]?.capa && <S.Capa_cont>{data_sis[tab]?.capa}</S.Capa_cont>}
                          <S.Capa_img src={data_sis[tab]?.capa_img} alt="capabilities"/>
                        </S.Capa_conts>
                      </S.Capa_sec>
                    }

                    {data_sis[tab]?.etc &&
                      <S.Etc_sec>
                        {data_sis[tab]?.etc.map((item, key) => (
                          <>
                            <S.SIS_title>{item.title}</S.SIS_title>
                            {item?.img && <img src={item.img} alt={item.title} style={{'marginTop' : '24px'}}/>}
                            {item?.video && <S.Etc_video src={item.video}/>}
                          </>
                        ))}
                      </S.Etc_sec>
                    }
                  </S.SIS>
                }
              </S.Article>
            </S.Content_sec>
          </S.Content>
        </S.Body>
      </S.Modal_cont>
    </S.Modal>
  )
}

export default CompanyModal;