import './App.css';
import './Font.css'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Footer, Nav } from './components';
import {Home, Company } from './pages'

function App() {
  return (
    <div className="App">
      <Router>
        <Nav/>
        <Routes>
          <Route path='/' element={<Home/>}/>
          <Route path='/company' element={<Company/>}/>
        </Routes>
        <Footer/>
      </Router>
    </div>
  );
}

export default App;
