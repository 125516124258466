import {styled} from 'styled-components';
import {Exit, Logo, Logo_notion, Menu, Upright, logo_cubi, logo_jikji} from '../assets'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';

const S = {
  Nav : styled.nav`
    width : 100vw;
    height : 108px;
    display : flex;
    justify-content : space-between;
    padding : 0 20vw;
    box-sizing: border-box;

    z-index : 50;
    position : absolute;
    top : 0;
    left : 0;
  `,
  Logo_link : styled(Link)`
    width : 144px;
    height : 37px;
    margin : auto 0;
  `,
  Logo : styled.img`
    width : 144px;
    height : 37px;
    filter: brightness(0) invert(1);
    object-fit : contain;
  `,
  Nav_list : styled.section`
    margin : auto 0;
  `,
  Company : styled(Link)`
    text-decoration : none;
    color : #ffffff;
    font-size : 18px;
    margin-right : 46px;
    font-weight : 600;
  `,
  Notion : styled.a`
    text-decoration : none;
    color : #ffffff;
    font-size : 18px;
    font-weight : 600;
  `,
  Notion_Logo : styled.img`
    width : 19px;
    height : 19px;
    margin-right : 5px;
    margin-bottom : -2px;
  `,


  M_Content : styled.section`
    width : 100%;
    height : 99px;

    position : absolute;
    top : 0;
    left : 0;
    z-index : 2;
  `,
  M_Menu_sec : styled.section`
    width : 100%;
    height : 100vh;
    margin : 0;
    background : #0000004d;
  `,
  Open : styled.section`
    height : 478px;
    box-sizing : border-box;
    background : linear-gradient(180deg, #000000 0%, #000000 90%, #00000000 100%);
    
    position : relative;
    box-sizing : border-box;
    padding : 65px 24px 32px;
    
    &:after {
      content : "";
      opacity : 10%;
      position : absolute;
    }
  `,
  Not_Open : styled.div`
    text-align : start;
    height : 100%;
    display : flex;
    align-items : flex-end;
    justify-content : space-between;
    background : linear-gradient(0deg, #00000000, #000000ff )
  `,
  Not_open_exit : styled.img`
    width : 16px;
    height : 16px;
    margin : 16px 20px;
  `,
  Menu_icon : styled.img`
    width : 24px;
    height : 24px;
    margin : 14px 20px;
    cursor : pointer; 
  `,
  M_Button : styled.button`
    width : 116px;
    height : 41px;
    border-radius : 22px;
    background : #ffffff;
    color : #000000;
    cursor : pointer;
    font-family : 'GosanjaOTF';
    font-size : 14px;
    line-height : 120%;
    letter-spacing : -0.01em;
  `,
  M_Menu : styled.div`
    text-align : start;
    font-family : 'GosanjaOTF';
    font-size : 24px;
    color : #ffffff;
    line-height : 33.6px;

    display : flex;
    flex-direction : column;

    margin-top : 40px;
  `,
  M_Menu_list : styled.a`
    padding : 0;
    margin : 14px 0;

    text-decoration : none;
    color : #ffffff;

    display : flex;
    align-items : center;

    & .arrow {
      width : 10px;
      height : 10px;
      margin-left : 10px;
      align-self : flex-end;
    }

    & .notion2 {
      margin-bottom : 10px;
    }
  `,
  M_Menu_logo : styled.img`
    height : 50px;

    &.jikji {
      height : 20px; 
    }

    &.notion {
      height : 21px;
      width : 21px;
      margin-right : 10px;
    }
  `,
  M_exit : styled.div `
    text-align : start;
    flex-grow : 1;
    display : flex;
    align-items : start;
    justify-content : start;
    & img {
      width : 24px;
      height : 24px;
    }
  `

}
const Nav = () => {
 
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024)
  const navigate = useNavigate()
  const location = useLocation().pathname;
  

  useEffect(() => {
    window.addEventListener('resize', setIsMobile(window.innerWidth < 1024));

    return () => window.removeEventListener('resize', setIsMobile(window.innerWidth < 1024))
  }, [])

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [open])

  const menuRef = useRef();

  const checkclick = (e) => {
    if(menuRef?.current && !menuRef?.current.contains(e.target)) {
      setOpen(!open)
    }
  }

  return (
    <>
      {isMobile ?
        <S.M_Content>
        {open ? 
          <S.M_Menu_sec onClick={(e) => checkclick(e)}>
            <S.Open ref={menuRef}>
              <S.M_exit><img src={Exit} onClick={() => setOpen(false)} alt="exit"/></S.M_exit>
              
              <S.M_Menu>
                <S.M_Menu_list onClick={() => {navigate('/company'); setOpen(false)}}>
                  COMPANY
                </S.M_Menu_list>
                {/* cubi link 연결하기 */}
                <S.M_Menu_list>
                  <S.M_Menu_logo src={logo_cubi} alt="logo_cubi"/>
                  <img src={Upright} alt="go" className='arrow'/>
                </S.M_Menu_list>
                <S.M_Menu_list href="https://jikji.ai" target='_blank'>
                  <S.M_Menu_logo src={logo_jikji} className='jikji' alt="logo_jikji"/>
                  <img src={Upright} alt="go" className='arrow'/>
                </S.M_Menu_list>
                <S.M_Menu_list href="https://chain-kitten-a36.notion.site/N3N-ac157fc4e2424fa6ad31b34dfbc3de95?pvs=4" target='_blank'>
                  <S.M_Menu_logo src={Logo_notion} className='notion' alt="logo_notion"/> We are
                  <img src={Upright} alt="go" className='arrow notion2'/>
                </S.M_Menu_list>
              </S.M_Menu>

            </S.Open>
          </S.M_Menu_sec>
          :
          <S.Not_Open>
            <S.Menu_icon src={Menu} onClick={() => setOpen(!open)} alt="icon_menu"/>
            {location === '/company' &&
            <S.Not_open_exit onClick={() => navigate('/')} src={Exit}/>
            }
          </S.Not_Open>
        }
        </S.M_Content>
        :
        <S.Nav>
          <S.Logo_link to='/'>
            <S.Logo src={Logo} alt="logo"/>
          </S.Logo_link>

          <S.Nav_list>
            <S.Company to='/company'>Company</S.Company>
            <S.Notion href='https://chain-kitten-a36.notion.site/N3N-HR-Team-f1f4c7b6f0a64626a3227fc360388e7b?pvs=4' target='_blank'>
              <S.Notion_Logo src={Logo_notion} alt="logo_notion"/>
              We are
            </S.Notion>
          </S.Nav_list>
        </S.Nav>
      }
    </>
  )
}

export default Nav;