import {styled} from 'styled-components';
import { DI, DV, Exit_B, Next, OI, Path, SIS, VI, logo_ver } from '../assets';
import Contact from './Contact';
import { useEffect, useState } from 'react';
import { CompanyModal } from '../components';
import { useNavigate } from 'react-router-dom';

const S = {
  Header : styled.header`
    background : #000000;
    height : 108px;
  `,
  BG_black : styled.section`
    background : #000000;

  `,
  Background : styled.section`
    width : 100vw;
    background : #ffffff;
    border-radius : 200px;
    padding-top : 94px;
  `,
  Logo : styled.img`
    width : 171px;
    height : 171px;
    object-fit : contain;

    &.exit {
      width : 30px;
      height : 30px;
      cursor : pointer;
    }
  `,

  Title : styled.h1`
    font-family : 'NanumSquareRoundOTF';
    font-size : 32px;
    font-weight : bold;
    line-height : 121%;
  `,
  Subtitle : styled.div`
    font-size : 18px;
    line-height : 32.5px;
    letter-spacing : -0.003em;
    font-weight : 400;
    padding : 0 10vw;
  `,
  Content_title : styled.h2`
    font-size : 40px;
    font-family : 'GosanjaOTF';
    line-height : 150%;
    letter-spacing : -0.01em;
    margin-top : 100px;
  `,
  Tech : styled.section`
    max-width : 1240px;
    display : flex;
    flex-wrap : wrap;
    margin : 0 auto;
    justify-content : center;
  `,
  Content_box1 : styled.div`
    max-width : 590px;
    width : 45vw;
    height : 190px;
    border : 1px solid #171717;
    cursor : pointer;
    margin : 10px;
    padding : 40px;
    box-sizing : border-box;
    overflow : hidden;

    position : relative;
  `,
  Content_box2 : styled.div`
    max-width : 1200px;
    width : calc(90vw + 20px);
    height : 230px;
    border : 1px solid #171717;
    cursor : pointer;
    margin : 10px 10px 0;
    padding : 40px;
    box-sizing : border-box;
    overflow : hidden;

    position : relative;
  `,
  Box_title : styled.div`
    font-family : 'GosanjaOTF';
    font-size : 30px;
    line-height : 100%;
    letter-spacing : -0.01em;
    margin : 0 20px 0 0;
    text-align : start;
  `,
  Box_content : styled.div`
    font-family : 'Pretendard';
    font-size : 14px;
    line-height : 140%;
    opacity : 50%;
    text-align : start;
    margin-top : 20px;

    display : flex;
    flex-wrap : wrap;

    position : relative;
    z-index : 2;
  `,
  Box_content_title : styled.div`
    width:20%;
  `,
  Box_content_content : styled.div`
    width:80%;
  `,
  Next : styled.img`
    width : 31px;
    height: 22px;
  `,
  Box_BG : styled.img`
    position : absolute;
    top : 40%;
    right : -5%;
    z-index : 1;
  `,
  Path_sub : styled.div`
    font-size : 20px;
    line-height : 140%;
    letter-spacing : -0.003em;
    padding : 0 10vw;
  `,
  Path_img : styled.img`
    width : 100%;
    margin : 50px 0 100px;
  `,
  PJ : styled.section`
    display : flex;
    max-width : 1200px;
    width : 90vw;
    justify-content : center;
    margin : 0 auto;
    padding-bottom : 200px;
  `,
  PJ_year : styled.div`
    font-size : 30px;
    line-height : 41.5px;
    font-weight : 800;
    margin-left : 20px;
    margin-right : 15px;
  `,
  PJ_cont : styled.div`
    font-size : 16px;
    line-height : 150%;
    letter-spacing : -0.007em;
    margin-top : 10px;
    text-align : start;
    word-break : keep-all;
  `,
  PJ_flex : styled.div`
    display : flex;
  `
}

const Company_W = () => {

  const [modal, setModal] = useState("");

  const closeModal = () => {setModal("")}

  useEffect(() => {
    if (modal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [modal])

  const navigate = useNavigate()

  return (
    <section>
      <S.Header/>
      <S.BG_black>
        <S.Background>
          <div style={{'display' : 'flex', 'justifyContent' : 'end', 'margin' : '0 10vw 0 0'}}>
            <S.Logo src={Exit_B} className='exit' onClick={() => navigate('/')} alt="logo"/>
          </div>
          <S.Logo src={logo_ver} alt="logo"/>
          <S.Title>New Enterprise to You Forever, N3N</S.Title>
          <S.Subtitle>
            N3N collects all data (video, sensor, DB, log, etc.) on the site that customers want to see regardless of type, <br/>
            It is a global data company that aims to provide insights by expressing it in the most intuitive way.<br/><br/>
            Since its establishment in 1999, it has been developing video intelligence technology based on unique video patents. <br/>
            Based on various experiences and excellent R&D personnel accumulated across all industries, including smart factories, smart cities, and customer experiences <br/>
            Provides innovative Intelligence platforms to customers in the era of Digital Transformation.<br/>
            As a result, we have carried out a number of projects in recognition of our technological prowess from global companies, and we have also attracted technological investment from Cisco.<br/><br/>
            Fast decision-making is essential in the era of 'hyper-connectivity' where all assets and objects communicate in real time.<br/>
            N3N expresses images and data containing vivid on-site information most easily and quickly for operators to understand and access.<br/>
            This gives customers instantaneous insight and allows them to make quick and accurate decisions.<br/>
            N3N presents a successful business model that can bring a vivid sense of realism to the collected IoT data and continue to generate value.
          </S.Subtitle>

          <S.Content_title>Technology</S.Content_title>
          <S.Tech>
            <S.Content_box1 onClick={() => setModal("VIDEO INTELLIGENCE")}>
              <div style={{'display' : 'flex'}}>
                <S.Box_title>VIDEO INTELLIGENCE</S.Box_title>
                <S.Next src={Next} alt="next"/>
              </div>
              <S.Box_content>POD (Pixel On Demand)<br/>VIDEO INTELLIGENCE ENGINE<br/>RDS(Remote Desktop System)</S.Box_content>
              <S.Box_BG src={VI} alt="content"/>
            </S.Content_box1>
            <S.Content_box1 onClick={() => setModal("DATA INTELLIGENCE")}>
              <div style={{'display' : 'flex'}}>
                <S.Box_title>DATA INTELLIGENCE</S.Box_title>
                <S.Next src={Next} alt="next"/>
              </div>
              <S.Box_content>DTG(Dynamic Topology Generator)<br/>DATA PLATFORM</S.Box_content>
              <S.Box_BG src={DI} alt="content"/>
            </S.Content_box1>
            <S.Content_box1 onClick={() => setModal("OPERATION INTELLIGENCE")}>
              <div style={{'display' : 'flex'}}>
                <S.Box_title>OPERATION INTELLIGENCE</S.Box_title>
                <S.Next src={Next} alt="next"/>
              </div>
              <S.Box_content>SOP (Standard Operation Procedure)<br/>VIDEO INTELLIGENCE ENGINE<br/>RDS(Remote Desktop System)</S.Box_content>
              <S.Box_BG src={OI} alt="content"/>
            </S.Content_box1>
            <S.Content_box1 onClick={() => setModal("DATA VISUALIZATION")}>
              <div style={{'display' : 'flex'}}>
                <S.Box_title>DATA VISUALIZATION</S.Box_title>
                <S.Next src={Next} alt="next"/>
              </div>
              <S.Box_content>LOCATION BASED VISUALIZTION<br/>MULTI LATER & ZOOMABLE UI<br/>DIGITAL TIME-SPACE CREATION</S.Box_content>
              <S.Box_BG src={DV} alt="content"/>
            </S.Content_box1>
            <S.Content_box2 onClick={() => setModal("SMART IoT SOLUTIONS")}>
              <div style={{'display' : 'flex'}}>
                <S.Box_title>SMART IoT SOLUTIONS</S.Box_title>
                <S.Next src={Next} alt="next"/>
              </div>
              <S.Box_content>
                <S.Box_content_title>Smart Factory</S.Box_content_title>
                <S.Box_content_content>Integrated visualization of facility/quality/operational data at production sites for quick and accurate decisions</S.Box_content_content>
                <S.Box_content_title>Smart IT Operations</S.Box_content_title>
                <S.Box_content_content>Increase MTTR/MTTD by providing IT operators with data visibility through visualization of IT infrastructure</S.Box_content_content>
                <S.Box_content_title>Smart Service Operations</S.Box_content_title>
                <S.Box_content_content>Visualize complex service processes into an intuitive UI for efficient operation/management</S.Box_content_content>
                <S.Box_content_title>Smart City</S.Box_content_title>
                <S.Box_content_content>Visualize the city's vast infrastructure and operations on a single screen to provide real-time monitoring and information</S.Box_content_content>
                <S.Box_content_title>Smart Asset Monitoring</S.Box_content_title>
                <S.Box_content_content>Real-time monitoring of corporate assets and public infrastructure</S.Box_content_content>
              </S.Box_content>
              <S.Box_BG src={SIS} alt="content"/>
            </S.Content_box2>
          </S.Tech>

          {modal !== "" && <CompanyModal closeModal={closeModal} type={modal}/>}

          <S.Content_title>Our Path</S.Content_title>
          <S.Path_sub>
            Since its inception in 1999, N3N has accumulated video intelligence technology, data structuring and visualization technology, patents and experience.<br/>Based on this, we provide solutions that innovatively address the different demands of various industries.
          </S.Path_sub>
          <S.Path_img src={Path} alt="content"/>

          <S.Content_title>Project</S.Content_title>
          <S.PJ>
            <S.PJ_year>2000</S.PJ_year>
            <S.PJ_cont>
              <hr/>
              일본, Hitachi 관제시스템 <br/>
              미국 CBS, 대선개표 방송(조지 부시-존 케리)<br/><br/>
              MICROSOFT, MIX 2008YOUTUBE 한국 런칭 쇼 <br/>
              U-City 사업 참여 인천대교, 교통관제 시스템
            </S.PJ_cont>
            <S.PJ_year>2010</S.PJ_year>
            <S.PJ_cont>
              <hr/>
              <S.PJ_flex>
                <S.PJ_cont>
                  서울핵안보회의, 보안모니터링 시스템<br/><br/>
                  현대자동차, 글로벌화재감시 시스템<br/>
                  포스코, 설비/품질 모니터링 시스템<br/>
                  인하대학교, 인하공전 CCTV  통합감시시스템<br/>
                  한국교통대학교, CCTV통합감시시스템<br/><br/>
                  경찰청, 교통정보 CCTV 시스템<br/>
                  전쟁기념관, 통합관제시스템 구축<br/>
                  현대글로비스, CCTV 통합관제시스템 구축<br/>
                  한양대학교 ERICA 캠퍼스, CCTV 통합감시시스템<br/><br/>
                  삼성전자, 해외 생산공장 모니터링 시스템<br/>
                  휴젤, GMP 공정관리 시스템<br/>
                  Jaipur, India Smart City<br/>
                  Miami, US Smart City<br/>
                  AT&T Mexico Cell Tower<br/>
                  JFE BigData Analytics<br/>
                  Carnival QoE<br/>
                </S.PJ_cont>
                <S.PJ_cont>
                삼성전자, IT Resource Management<br/>
                하나멤버스, Digital Transformation 시스템<br/>
                JFE 철강, 공정 품질 관리 시스템<br/>
                경남소방본부, 통합안전 모니터링 시스템<br/>
                통영시청, 해상안전 시스템<br/><br/>

                부산광역시, 스마트시티 통합 모니터링 시스템<br/>
                KDDI, 자율주행자동차 운영 시스템<br/>
                롯데마트, 베트남 비즈니스 현황 모니터링 시스템<br/>
                화승 VINA, 스마트 팩토리 생산 관리 시스템<br/>
                EXEO, 설비 관리 시스템<br/>
                한중NCS, 스마트 팩토리 통합 모니터링 시스템<br/>
                QRT, Lab 통합 모니터링 시스템<br/>
                한국서부발전, 발전설비 통합 모니터링 시스템<br/>
                한국중부발전, 통합관제 시스템<br/>
                한국남부발전, 스마트 재난관리 시스템<br/>
                중소기업중앙회, 스마트공장 운영시스템<br/><br/>

                하나금융지주, 콜라보 플랫폼 시각화 모니터링 시스템<br/>
                스마트공장 보급확산사업 (경남, 경기, 전북 등)<br/>
                통영거제 해상안전시스템<br/>
                영원무역, 통합모니터링 시스템<br/>
                모노리스, 제주카트 경기장 모니터링<br/>
                ES청원, 통합모니터링 시스템<br/>
                한국남동발전, 과학화보안 통합관제 시스템<br/>
                CJ그룹, PLAYCAN 배회모니터링<br/>
                삼성전자, PLAYCAN 피플카운팅<br/>
                </S.PJ_cont>
              </S.PJ_flex>
            </S.PJ_cont>
          </S.PJ>
        </S.Background>
      </S.BG_black>
      <Contact/>
    </section>
  )
}

export default Company_W;