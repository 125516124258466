import {OV_SF, OV_SAM, OV_SC, OV_SIO, OV_SSO, BN1_SAM, BN2_SAM, BN1_SC, BN2_SC, BN1_SF, BN2_SF, BN3_SIO, BN1_SIO, BN2_SIO, BN1_SSO, BN2_SSO, CG1_SF, CG2_SF, CG3_SF, CG4_SF, CG1_SIO, CG2_SIO, CG3_SIO, TC_SF,CP_SF, CP_SIO, CP_SSO, ETC_SAM, ETC_SC, BN1_SFM, BN1_SIOM, BN2_SIOM, BN3_SIOM, BN4_SIOM, BN1_SSOM, BN1_SCM, BN1_SAMM, TC_SFM, CP_SFM, CP1_SIOM, CP2_SIOM, CP_SSOM, ETC_SCM, ETC_SAMM} from '../assets'

const data = {
  "SMART FACTORY" : {
    overview : [
      "생산 현장의 IoT 데이터를 수집/분석하여 산업 현장에서 생산에 관련된 모든 부분을 한눈에 조망 가능하고 실시간으로 주요 KPI 및 이슈를 확인할 수 있습니다.생산성 향상, 불량률 감소, 효율적 에너지 활용, 사고 예방 등에 필요한 데이터를 다이내믹하게 시각화 하므로 문제의 원인을 빠르게 진단하고 해결할 수 있으며, 더 나아가 관련 인사이트(Insight) 를 발견하게 합니다.",
      "Smart Factory Solution은 이종의 모든 Industrial IoT 빅데이터를 직관적으로 시각화 하여 운영 효율성을 높이고 쉽게 문제 해결, WE monitor, detect, and predict issues which occur in factories without any limitations",
    ],
    overview_img : OV_SF,
    benefits : [BN1_SF, BN2_SF],
    m_benefits : [
      "공장 내 설비 및 운영 IoT 데ㅔ이터를 Single View로 통합 시각화하여 생산 공정상의 완벽한 데이터 가시성 확보",
      "직관적인 내비게이션과 실시간 알람시스템으로 신속한 이슈 파악 및 해결",
      "새로운 장비 및 시각화 영역이 변경, 추가되어도 쉽게 확장 가능"
    ],
    m_benefits_img : [BN1_SFM],
    category : [
      {
        img : CG1_SF,
        title : "현장 원격 모니터링 및 제어",
        content : "사업장과 생산설비를 한눈에 실시간으로 모니터링하여, 다양한 이슈에 대해 즉시 대응 및 빠른 의사 결정을 가능하게하는 시스템 환경 마련 및 효율적인 인적/조직관리,생산성 향상에 기여.공장의 복잡한 설비 인프라를 클릭 한 번으로 화면 내에서 직접 설비 제어"
      },
      {
        img : CG2_SF,
        title : "품질 불량 추적관리",
        content : "빅데이터 기반 생산 단계별 제품의 상태가 정상인지 체크하고, 문제가 발견된 LOT에 대해서는 생산 과정을 추적하여 최적의 해결 방안 모색\n- 현재 생산 중인 LOT생산 중단 의사 결정을 내릴 수 있게 하여, 생산 손실의 최소화에 기여\n- 생산 완료 LOT문제의 근본 원인을 찾아내어 재발 방지 대책 마련 및 근본적인 제품 품질, 신뢰성 향상 기여"
      },
      {
        img : CG3_SF,
        title : "실시간 이상 징후 파악 및 문제해결",
        content : "주요 비즈니스 성과지표(KPI)에 영향을 주는 상세 요소들을 계층화된 알람으로 표시하여, 문제의 원인을 보다 빠르고 직관적으로 찾아 해결할 수 있는 시간(MTTD/MTTR)을 혁신적으로 감소"
      },
      {
        img : CG4_SF,
        title : "Big Data를 활용한 예지 정비",
        content : "BigData 엔진을 활용한 분산된 Industry IoT 데이터의 수집 및 통합 시각화로 장비의 직관적이고 분석적인 대쉬보드 제공\n- IoT 공정 데이터 히스토리와 영상의 통합 분석/시각화로 품질 오류 관리\n- 공정 장비의 History 데이터로 수명 및 부품 교체 시기 관리\n- 축적된 빅데이터를 활용한 오류 예측 및 진단으로 예방 정비"
      }
    ],
    tech : "어떠한 종류의 데이터라도, N3N의 전용 빅데이터 엔진 DP(Data Platform) 혹은 상용 빅데이터 엔진인 Splunk를 사용하여 쉽고 빠르게 데이터를 수집함",
    tech_img : TC_SF,
    m_tech_img : TC_SFM,
    capa : "엔쓰리엔은 Physical Location에 기반한 Real-time Data를 Zoom UX를 통해 담아내며,\nMap 상에서 특정 시간대별 Status를 제공해 입체적 조망이 가능한 Time-space를 만듭니다.",
    capa_img : CP_SF,
    m_capa_img : [CP_SFM],
  },
  "SMART IT Operations" : {
    overview : [
      "IT 운영 솔루션은 세계 표준 가이드인 ITIL(IT Infrastructure Library)에 기반한 효율적이고 증명된 IT 인프라 관리 서비스로써, 혁신적인 UI/UX를 바탕으로 IT 문제 및 리스크 발생시 최적 대응을 가능하게 하는 솔루션입니다. 또한 솔루션을 통해 기업 IT 인프라에 대한 운영자의 Governance 확보로 조직 내 협업을 증진하고 이를 통해 실시간으로 문제를 진단, 문제의 근본적인 원인을 빠르게 찾아냅니다.",
      "Smart IT Operations는 복잡한 IT인프라와 가상 머신 상의 모든 애플리케이션을 직관적으로 시각화하여 운영자가 문제의 원인을 바로 찾고(MTTD) 실시간 대응(MTTR)을 가능하게 합니다.",
      "Smart IT Operations solutions enables the operator to have a data governance across the domains by detecting the root cause of the issues in real time and resolving the issues quickly."
    ],
    overview_img : OV_SIO,
    benefits : [BN1_SIO, BN2_SIO, BN3_SIO],
    m_benefits : [
      "가상 머신 상의 복잡한 하드웨어 인프라와 비즈니스 애플리케이션에 대한 운영자의 100% 가시성 제공",
      "문제의 근본적인 원인은 물론 잠재적인 이슈까지도 파악/분석이 가능하므로 MTTD/MTTR 확보로 운영 효율성 향상",
      "데이터와 운영자, 운영자와 고객 서비스 사이의 네트워크 품질 가시성 확보로 관리 효율성 및 QoS/QoE의 본질적 향상",
      "빅데이터 솔루션과의 완벽한 통합으로 데이터 활용 및 가용성 높임"
    ],
    m_benefits_img : [BN1_SIOM, BN2_SIOM, BN3_SIOM, BN4_SIOM],
    category : [
      {
        img : CG1_SIO,
        title : "서비스 품질 / 경험 품질(QoS / QoE) 관리",
        content : "- 네트워크 및 서비스에 대한 즉각적인 시각화로 실시간 네트워크 서비스 품질 향상\n- On/Off line 네트워크 커뮤니케이션 상태와 장비의 현황을 한눈에 쉽게 모니터링\n- 최종 사용자의 사용 행태 및 경험을 실시간 시각화하여, 체감 품질 측정 및 향상\n- 고객 동선을 포함, IT 인프라와 고객 애플리케이션 품질 관리로 운영자의 실시간 데이터 통제력을 확보ㆍ고객 이동경로, 서비스 지연 및 장애 발생률, 인터넷 접속 기록 및 데이터 사용량 분석, 인터넷 접속 위치 및 위험레벨 표시 등"
      },
      {
        img : CG2_SIO,
        title : "데이터센터 인프라 관리 (DCIM)",
        content : "- DCIM 운영자와 데이터 센터 사용자간 데이터를 공유할 수 있는 간편하고 단일화된 UI로 논리적, 물리적 아키텍쳐를 한 화면에 표출\n- 데이터센터의 전력, 냉방, 서버 등 주요 인프라 및 기기의 헬스 정보를 시각화하여 break down 예방 및 MTTD / MTTR 획기적 감소\n- 빅데이터 접근성 및 활용도를 극대화하여 기존의 다양한 시스템의 교체없이 통합적 모니터링 가능* MTTD : Mean Time To Detect / MTTR : Mean Time TO Resolve"
      },
      {
        img : CG3_SIO,
        title : "애플리케이션 성능 모니터링 (APM)",
        content : "- 다양한 애플리케이션 성능 관리 시스템을 한 화면에서 데이터센터의 상황과 함께 통합 모니터링\n- WEB 개발자, 설비 담당자, IT 담당자 간의 효율적인 의사결정으로 신속한 문제 해결 가능\n- 논리맵구조를 생성하여 즉각적으로 문제의 원인을 파악하고 대처 가능\n- 모든 애플리케이션 식별 및 종속성 시각화"
      }
    ],
    capa_img : CP_SIO,
    m_capa_img : [CP1_SIOM, CP2_SIOM],

  },
  "SMART Service Operations" : {
    overview : [
      "스마트 서스 오퍼레이션 솔루션은 실시간 빅데이터, 복잡한 이벤트, 프로세스 및 운영의 단순 시각화가 아닌 운영자가 이를 통해 데이터 Control과 Governance를 가능하게 합니다. 또한 수집된 데이터에 대한 실시간 분석을 통한 각 비즈니스 니즈에 맞는 맞춤형 프로세스 시각화를 제공합니다. 이를 통해 구현된 라이브 대시보드는 최적화된 서비스 제공과 함께 운영자와 의사결정자로 하여금 새로운 비즈니스 모델에 대한 통찰력을 제공합니다.",
      "Smart Service Operations은 기업의 복잡한 워크플로우, 프로세스, IoT 데이터에 대한 운영자의 Data Governance 확보로 지속적이고 즉각적인 인사이트를 제공하므로 비지니스 상의 위험을 제거하고 새로운 기회를 찾게 합니다.",
      "Smart Service Operations solution optimizes operational efficiency, makes informed decisions and finds business threats and opportunities."
    ],
    overview_img : OV_SSO,
    benefits : [BN1_SSO, BN2_SSO],
    m_benefits : [
      "실시간 Data, 복잡한 워크플로우, 운영 프로세스를 통합한 하나의 서비스 운영 채널로 시각화",
      "여러 시스템의 통합된 뷰로 비즈니스 위험과 기회를 직관적으로 빠르게 파악 - 효율적인 운영으로 급변하는 비즈니스 대응",
      "비즈니스 목표에 맞게 정의된 분석 기반의 자동화된 프로세스 및 워크플로우로 시기 적절한 대응",
      "사용자 니즈에 맞게 어떠한 Legacy 시스템이나 산업으로도 적용 가능"
    ],
    m_benefits_img : [BN1_SSOM],
    category : [
      {
        title : "QoE (경험 품질) 향상",
        subtitle : "운영자가 End user 에 대한 서비스 체감 품질 (QoE) 측정/ 제어로 경험 품질 향상",
        content : "- 실시간 사용자가 체감하는 On/Off line 네트워크 품질 수집/측정/분석\n- 고객 서비스에 대한 정확한 네트워크 서비스 히스토리 및 행태 정보 분석\n- 마케팅 포함, 여러 부서가 공유할 수 있는 형태의 리포팅 대시보드 제공\n- 고객 경험을 최적화 할 수 있는 고부가가치 툴 제공\n- 고객 서비스 만족을 위한 새로운 혹은 맞춤형 서비스 모델 창출"
      },
      {
        title : "서비스 채널 통합 운영",
        subtitle : "분산된 고객 서비스 채널 통합 운영으로 어카운트 별 일원화된 정보제공, 고객 서비스 만족도 및 커뮤니케이션 효율성 향상",
        content : "- 하나의 통합된 화면을 통해 어카운트, 국가별 IoT 디바이스의 현황 파악\n- 개별 부서가 각자 고객을 대응하던 이전과 달리 고객 서비스 운영에 대한 일관성 재고\n- IoT 데이터 운영 비용 및 대내외적 커뮤니케이션 비용의 절감\n- 수집되는 데이터를 바탕으로 한 디바이스 자동 제어 서비스 개발 가능"
      },
      {
        title : "비즈니스 운영 대시보드",
        subtitle : "하나의 통합된 비즈니스 운영 프리젠테이션 대시보드를 통해 실시간 비즈니스 운영 및 프로세스의 분석 및 모니터링",
        content : "- 실시간 프로세스 및 운영 데이터 분석 결과의 비교 및 통합 차트화\n- 과거 및 현재 이벤트 연관성을 파악하여 트랜드 및 패턴 예측\n- IoT 데이터 및 이벤트의 실시간 대시보드 프리젠테이션"      
      },
      {
        title : "프로세스 인텔리전스",
        subtitle : "지속적이고 확장 가능한 프로세스 시각화로 비즈니스 위험 요소 제거 및 운영 효율성 향상",
        content : "- 프로세스 결과물의 지속적인 모니터링, 측정, 개선 가능\n- 사각지대 없는 시각화 프로세스로부터 얻는 깊은 통찰력\n- 실시간 비즈니스 프로세스/운영에 대한 360도 뷰 제공\n- 고객 경험에 영향을 미치는 변수 및 정책 이해로 프로세스 단축"
      }
    ],
    capa_img : CP_SSO,
    m_capa_img : [CP_SSOM]
  },
  "Smart City" : {  
    overview : [
      "Smart City 솔루션은 거대한 도시 인프라에서 생성되는 센서, 맵, 비디오 등 다양한 IoT 데이터 소스를 수집 및 변환하여 하나의 통합된 화면에 시각화하므로 운영의 효율성을 높이고 도시 전체를 한눈에 모니터링 할 수 있습니다. 더 나아가, 시민과의 소통을 통해 대민 서비스 개선 및 실시간 민원 처리를 가능하게 하는 운영체계로 탈바꿈하고 있습니다. 지역사회가 요구하는 맞춤형 공공서비스를 통해 도시민의 만족도 향상 뿐만 아니라 직접 참여를 유도해 정부와 시민이 함께 만들어가는 쾌적하고 편리하고 스마트한 도시를 지향합니다.",
      "Smart City는 도시 내 서비스 운영, 프로세스, IoT 데이터 소스에 대한 통합적인 뷰를 제공하므로 도시 행정의 효율성과 투명성을 높이고, 제한된 자원을 효과적으로 배분하며 더 나아가 도시민의 참여를 유도해 서비스 만족도를 높입니다.",
      "Smart City solution improves operational efficiency, allocates resources and increases citizens' involvement in their communities and government."
    ],
    overview_img : OV_SC,
    benefits : [BN1_SC, BN2_SC],
    m_benefits : [
      "도시 인프라 및 운영의 모든 데이터를 통합 제공(싱글뷰)으로 운영의 가시성 확보",
      "언제 어디서나 도시 운영 정보의 빠르고 정확한 액세스로 편리하고 만족도 높은 도시로 레벨업", 
      "솔루션, 애플리케이션 및 IoT 디바이스를 자유자제로 커스터마이즈 및 확장 가능",
      "센서와 도시 자원의 통합, 효율적 운영, 분배로 비용 절감"
    ],
    m_benefits_img : [BN1_SCM],
    category : [
      {
        title : "모니터링/운영 센터",
        title_en : "Monitoring/Operation Center",
        subtitle : "시티 인프라 뿐만 아니라 도시운영의 최우선 과제, 요구사항, 예산 등의 행정 관련 사항을 인터랙티브하게 시각화",
        content : "- 자치단체별로 추진중인 최우선 과제 및 행정 영역별 KPI 시각화\n- 교통, 공항, 환경, 민원정보 등 실시간 도시운영의 실시간 데이터 시각화\n- 국가 재난 및 비상 사태 대비 주요 시설 제어/경보 시스템/커뮤니케이션 가능"      
      },
      {
        title : "도시 인프라/리소스 관리",
        title_en : "City Infra/Resources Mgmt",
        subtitle : "주차, 교통, 가로등, 상하수도, 쓰레기 등 도시 인프라/리소스의 효율적인 관리/운영/분배",
        content : "- 교통혼잡 시간, 교통 유도 및 주차 정보 안내로 효율적인 인프라 운영\n- 가로등, 주차 시스템, 도로사고, 공사 등의 정보 공유와 제어로 에너지 절감\n- 상하수도, 종량제 시스템의 효율적인 관리"
      },
      {
        title : "보안 및 방범",
        title_en : "Security & Safety",
        subtitle : "도시민의 보안 및 안전을 위한 CCTV 분석 및 예방 시스템 제공",
        content : "- 지역 모니터링 : 도시 내 실시간 CCTV 카메라 액세스/분석 및 소셜 미디어 감시로 위험 예방\n- 사고 감지 및 관리ㆍ실시간 CCTV 분석으로 위험/사고 감지 및 운영자에게 잠재적 위험 리포트ㆍ모든 사고에 대한 기록 분석으로 범죄 예방\n- 운영 : 센서, 비디오 인프라 및 정책에 대한 설정 및 관리 용이\n- 분석 : 범죄 및 사고에 대한 발생 빈도, 로케이션 등의 분석으로 패턴 파악 및 재발 위험 감지"
      },
      {
        title : "디지털 커뮤니티 ",
        title_en : "Digital-connected Community",
        subtitle : "도시 행정과 연결된 모바일앱을 통해 도시민이 도시 개발/행정/운영에 적극 참여하고 발전할 수 있는 시스템",
        content : "- 전략적 IoT 디바이스 전파에 따른 도시민과의 적극적인 커뮤니케이션으로 발전 방향성 수립 및 전개\n- 디지털화된 생활 요금 관련 청구/확인 시스템으로 양방향 민원 편의 정보 제공\n- 재난/사고, 환경 정보의 리포팅 가능한 정보 공유로 건강 증진"
      },
    ],
    etc : [
      {
        title : "SMART CITY OPS PLATFORM DATA COLLECTOR",
        img : ETC_SC,
        m_img : ETC_SCM,
        video : "https://www.youtube.com/embed/TN6LIeNGMAM?si=QMLnvXwDvInyxPGr"
      }
    ]
  }, 
  "Smart Asset Monitoring" : {
    overview : [
      "IoT의 발달은 기업 내 주요 자산에 대한 새로운 방식의 관리방법을 요구하고 있고, 기업은 네트워크로 연결된 모든 자산의 성능과 상태에 대한 통찰력을 필요로 합니다. 스마트 자산 모니터링 솔루션은 기업 내 모든 연결된 자산(connected assets)의 위치, 상태, 이용률, 가용성에 대한 완벽환 가시성을 하나의 통합된 뷰로 담아냅니다. 사용자는 실시간/히스토리 분석으로 사용 패턴 및 움직임을 파악하고 패턴을 예측하며 자산의 유지 보수 스케줄 정립과 프로세스를 최적화는 물론 에너지를 보다 효율적으로 관리합니다.",
      "Smart Asset Monitoring은 빌딩, 산업 현장, 리테일 및 공장 시설을 포함한 모든 연결된 기업 내 자산(Connected Asset)에 대한 원격 모니터링, 제어, 이슈 추적을 통한 비즈니스 통찰력을 가능하게 합니다.",
      "Smart Asset Monitoring solution enables you to monitor and control the connected enterprise assets remotely and track issues in real time for mission critical sites.",
    ],
    overview_img : OV_SAM,
    benefits : [BN1_SAM, BN2_SAM],
    m_benefits : [
      "기업 내 모든 연결된 자산(connected assets)에 대한 완벽한 가시성 확보",
      "자산의 실시간 원격 트래킹(tracking) 및 모니터링(monitoring)으로 에너지 절감 및 효율적 자산 관리",
      "실시간 대량의 FULL HD 영상 전송이 가능하므로 해외 및 원격지의 현장 정보 파악 용이 (POD 기술 적용)",
      "자산의 사용 패턴/행태 파악으로 효율적인 자산 관리, 운영비 절감 및 유지보수 용이"
    ],
    m_benefits_img : [BN1_SAMM],
    category : [
      {
        title : "원격 모니터링/제어",
        title_en : "Remote Monitoring/Control",
        subtitle : "기업의 내 네트워크로 연결된 인프라/자산의 모니터링 및 제어",
        content : "- POD 기술 활용으로 대역폭 문제 없이 전 세계 수백 개 현장 영상(Full HD급)을 실시간 송/수신- 수집된 IoT 및 CCTV 데이터를 통해 얻어지는 정보를 통합하여 고도화된 현장 데이터 모니터링 체계 구현 - RDS 기술을 활용하여 필요 자원의 원격 제어"
      },
      {
        title : "기업 주요 자산 관리 ",
        title_en : "Enterprise Asset Mgmt",
        subtitle : "주요 자산에 대한 로케이션 별 통합된 뷰와 정보 제공으로 분석적 관리",
        content : "- 실시간 카메라 좌표 포함 영상을 고해상도 맵에 통합/시각화\n- Interactive Playback을 통한 물리적 원인에 대한 영상 분석 기능\n- 시설물 별 사이트 레이아웃 제공으로 기업 자산별 정보 관리\n- 기업 자산에서 수집된 IoT 데이터에 대한 직관적 시각화 (센서 데이터 : 온도, 진동, 액세스, 변환기, 발전기 출력, 연료 수준, 모션, 연기, 카메라)"
      },
      {
        title : "시설 보안 / 안전",
        title_en : "Security & Safety",
        subtitle : "현장 체크 필요 없이 원격에서 기업 자산에 대한 CCTV 모니터링, 액세스 제어, 사건/사고 제어",
        content : "- 실시간 CCTV 영상 및 과거 영상 기록 분석을 통한 범죄 예방\n- 액세스 제어: 빌딩/시설 보안 시스템의 제어로 미확인 액세스 식별 및 상황 별 인지\n- 사고, 위급 상황, 시설물 관리에 대한 협업 기능 및 대응"
      },
      {
        title : "방재 시스템",
        title_en : "Alarm System",
        subtitle : "실시간 데이터/임계 값에 따른 알람 시스템으로 위험상황 인지 및 방재",
        content : "- 이상 징후에 대한 시나리오 별 임계값 설정을 통해 위험시 자동 경보 시스템 발동\n- 사고 감지 시, CCTV 화면을 통해 즉각 문제 파악 및 자동 SOP에 따른 안전 확보\n- 재난/사고 상황 시나리오 기반 SOP 자동 표출 및 유관 기관 연계\n- 화재 감지 시스템으로 재난 대비 및 예방적 대책 마련\n- 원클릭 유관 기관 연계 시스템으로 안전 확보"
      }
    ],
    etc : [
      {
        title : "",
        video : "https://www.youtube.com/embed/QOTVeQlnWQ4?si=tT_MRfSKgcgd-G50"
      },
      {
        title : "SYSTEM ARCHITECTURE",
        img : ETC_SAM,
        m_img : ETC_SAMM
      }
    ]
  }
}

export default data;