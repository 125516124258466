import { useState, useEffect } from 'react';
import {styled} from 'styled-components';
import { GetInTouch, GetInTouchM } from '../components';

const S = {
  Contact : styled.section`
    width : 100vw;
    height : calc(100vh - 324px);
    background : #000000;

    display : flex;
    flex-direction : column;
    align-items : center;
    justify-content : center;

    @media(max-width : 1024px) {
      background : #171717
    }
  `,
  Title : styled.h2`
    font-size : 32px;
    color : #ffffff;
    font-family : 'GosanjaOTF';
    margin-bottom : 10px;
  `,
  Content : styled.p`
    font-size : 15px;
    color : #ffffff;
    line-height : 21.3px;
    margin-bottom : 30px;
  `,
  Button : styled.button`
    width : 127px;
    height : 40px;
    border-radius : 48px;
    color : #171717;
    font-size : 14px;
    font-family : 'GosanjaOTF';
    cursor : pointer;
  `
}

const Contact = () => {

  const [isopen, setIsopen] = useState(false);
  
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);

  useEffect(() => {
    window.addEventListener('resize', setIsMobile(window.innerWidth < 1024));

    return () => window.removeEventListener('resize', setIsMobile(window.innerWidth < 1024))
  }, [])

  const closeModal = () => {setIsopen(false)}

  useEffect(() => {
    if (isopen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isopen])


  return (
    <S.Contact>
      <S.Title>GET IN TOUCH</S.Title>
      <S.Content>'Global AI Platform Provider' 엔쓰리엔과<br/>함께할 여러분의 많은 관심과 지원을 바랍니다.</S.Content>
      <S.Button onClick={() => setIsopen(true)}>CONTACT US</S.Button>

      {isopen && !isMobile &&
        <GetInTouch closeModal={closeModal}/>
      }
      {isopen && isMobile && 
        <GetInTouchM closeModal={closeModal}/>
      }
    </S.Contact>
  )
}

export default Contact;