import {styled} from 'styled-components';
import { useState, useRef, useEffect } from 'react';
import { BG_jikji1, Next_main, logo_jikji } from '../assets';

const S = {
  Sticky : styled.section`
    width : 100vw;
    height : 400vh;
    position : relative;
  `,
  
  Jikji : styled.section`
    width : 100vw;
    height : 100vh;
    font-family : 'GosanjaOTF';

    position : sticky;
    top : 0;
    left : 0;
    z-index : 1;

    display : flex;
    flex-direction : column;
    justify-content : center;
    align-items : center;

    overflow : hidden;
    background : no-repeat center/cover url(${BG_jikji1});
  `,
  Left : styled.section`
    width : 100vw;
    height : 50vh;
    font-family : 'Pretendard';

    &.logo {
      display :flex;
      justify-content : end;
    }

    &.content {
      height : 60vh;
      margin-top : -10vh;
    }
  `,
  L_cont : styled.div`
    padding-left : 10vw;
    padding-bottom : 10vh;
    margin-top : -10vh;
    box-sizing : border-box;

    position : absolute;
    bottom : 0;
  `,
  B_cont : styled.section`
    width : 100%;
    padding : 3vw 10vw 0;
    box-sizing : border-box;
    display : flex;
  `,
  Box : styled.div`
    width : 25%;
  `,
  Box_title : styled.div`
    font-size : 2.5vw;
    // font-size : 1.5em;
    font-weight : 700;
    line-height : 120%;
    letter-spacing : -0.003em;
    color : #ffffff;
    margin : 0 0 40px;
    text-align : start;
  `,
  Box_tag : styled.div`
    width : auto;
    text-align : start;
  `,
  Box_tag_item : styled.div`
    background : linear-gradient(150deg, #ffffff1a 0%, #ffffff00 100%);
    border-radius : 36px;
    border : 1px solid #ffffff33;

    font-size : 1.5vw;
    font-weight : 700;
    line-height : 120%;
    letter-spacing : -0.003em;
    color : #ffffff;
    filter : drop-shadow(0 3px 3px #00000040);

    display : inline-block;
    padding : 14px 20px;
    text-align : center;
    margin : 11px 100% 0 0;
    white-space : nowrap;
  `,
  R_cont : styled.div`
    width : 30%;
    height : 100%;
    display : flex;
    flex-direction : column;
    justify-content : center;
    padding-right : 10vw;
  `,
  L_Title : styled.h2`
    font-size : 5vw;
    font-weight : 800;
    line-height : 120%;
    letter-spacing : -0.01em;
    color : #ffffff;
    margin :18px 0;
    white-space : nowrap;
    text-align : start;
  `,
  Logo : styled.img`
    width : 220px;
    height : 51px;
  `,
  Content : styled.p`
    text-align : end;
    font-size : 18px;
    line-height : 163%;
    letter-spacing : -0.001em;
    color : #ffffff;
  `,
  Homepage : styled.a`
    font-family : 'Pretendard';
    position : absolute;
    width : 100vw;

    font-size : 200px;
    background : linear-gradient(0deg, #000000 0%, #dbdbbc 100%, #798daf 88%, #000000 100%);

    color : transparent;
    -webkit-background-clip : text;

    cursor : pointer;
  `,
  Next_icon : styled.img`
    width : 161px;
    height: 161px;
    margin-left : 26px;
  `
}

const Jikji2 = () => {
  const [scroll, setScroll] = useState(0);
  const tmp = useRef(null);
  
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      const newScroll = currentScrollY / (window.innerHeight * 3);
      setScroll((-(window.innerHeight * 8 + 360)/(window.innerHeight * 3)) + newScroll);
    };

    // 스크롤 이벤트 리스너 등록
    window.addEventListener('scroll', handleScroll);

    // 클린업 함수
    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const getShow = (baseScroll, state='content') => {
      let cursor = 'default';
      
      // 스크롤 값에 따른 활성화 여부 결정
      let isActive = scroll > baseScroll;

      if (state === 'title' || state === 'menu') {
        isActive = scroll <= baseScroll;
      }
     
      if (state === 'url' && isActive) {
        cursor = 'pointer'
      } else if (state === 'url' && !isActive ) {
        cursor = 'default'
      }
  
      // 활성화되지 않았을 때는 아래에서 시작
      const translateY = isActive ? 0 : 50; // 시작 위치 (아래에서 50px)
      
      // 활성화 상태에 따른 opacity 계산
      const opacity = isActive ? 1 : 0;

      // 활성화 여부에 따라 transition을 조정하여 부드러운 입장과 퇴장을 설정
      const transition = 'opacity 1s ease-out, transform 1s ease-out';

      return {
          opacity: opacity,
          transform: `translateY(${translateY}px)`,
          transition: transition,
          cursor
      };
      
  } 


  return (
    <S.Sticky ref={tmp}>
      <S.Jikji>
        <S.Left className='logo'>
          <S.R_cont style={getShow(0.8, 'menu')}>
            <div style={{'width' : '100%', 'textAlign' : 'end'}}>
              <S.Logo src={logo_jikji} alt="logo"/>
            </div>
            <S.Content>Based on the technologies of Al Network Platform where all Al devices are hyper-connected, We build the HPC environments that our customers desire.</S.Content>
          </S.R_cont>
        </S.Left>
        <S.Left style={getShow(0.8, 'menu')} className='content'>
          <S.L_cont style={getShow(0.2, 'title')}>
            <S.L_Title>HPC</S.L_Title>
            <S.L_Title>Workstation</S.L_Title>
            <S.L_Title>Server</S.L_Title>
            <S.L_Title>Single POD</S.L_Title>
          </S.L_cont>
          <S.B_cont style={getShow(0.2)}>
            <S.Box>
              <S.Box_title>DATA CENTER</S.Box_title>
              <S.Box_tag>
                <S.Box_tag_item>Co-location</S.Box_tag_item>
                <S.Box_tag_item>On-Premise</S.Box_tag_item>
                <S.Box_tag_item>Cloud Service</S.Box_tag_item>
              </S.Box_tag>
            </S.Box>
            <S.Box style={getShow(0.35)}>
              <S.Box_title>SW</S.Box_title>
              <S.Box_tag>
                <S.Box_tag_item>Infra Engineering</S.Box_tag_item>
                <S.Box_tag_item>Cloud Engineering</S.Box_tag_item>
                <S.Box_tag_item>AI Developer</S.Box_tag_item>
              </S.Box_tag>
            </S.Box>
            <S.Box style={getShow(0.5)}>
              <S.Box_title>SOLUTION</S.Box_title>
              <S.Box_tag>
                <S.Box_tag_item>Consulting</S.Box_tag_item>
                <S.Box_tag_item>Facility Design</S.Box_tag_item>
                <S.Box_tag_item>Cloud Operation</S.Box_tag_item>
              </S.Box_tag>
            </S.Box>
            <S.Box style={getShow(0.65)}>
              <S.Box_title>INFRA</S.Box_title>
              <S.Box_tag>
                <S.Box_tag_item>Infra Engineering</S.Box_tag_item>
                <S.Box_tag_item>Facility Equipment</S.Box_tag_item>
              </S.Box_tag>
            </S.Box>
          </S.B_cont>
        </S.Left>
        <S.Homepage style={getShow(0.8, 'url')} onClick={() => {if (scroll >0.8) {window.location.href="https://jikji.ai"}}}>
          GO JIKJI
          <S.Next_icon src={Next_main} alt="next"/>
        </S.Homepage>
      </S.Jikji>
    </S.Sticky>
  )
}

export default Jikji2;