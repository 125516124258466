import {styled} from 'styled-components';
import { useState, useRef, useEffect } from 'react';
import { BG_jikji1_m, Next_W, logo_jikji } from '../assets';

const S = {
  Sticky : styled.section`
    width : 100vw;
    height : 400vh;
    position : relative;
  `,
  
  Jikji : styled.section`
    width : 100vw;
    height : 100vh;
    font-family : 'GosanjaOTF';

    position : sticky;
    top : 0;
    left : 0;
    z-index : 1;

    display : flex;
    flex-direction : column;
    justify-content : space-around;
    align-items : center;

    background : no-repeat center/cover url(${BG_jikji1_m});
    overflow : hidden;

    padding : 48px 32px;
    box-sizing : border-box;
  `,
  Top : styled.section`
    width : 100%;
    height : calc(var(--vh, 1vh) * 50 - 90px);

    display : flex;
    flex-direction : column;
    justify-content : center;
    align-items : center;
  `,
  Center : styled.section`
    width : 100%;
    height: 180px;
    
    display : flex;
    align-items : center;
    white-space : nowrap;
    font-family : 'Pretendard';
  `,
  Center_text : styled.div`
    font-size : 120px;
    width : 100%;
    background : linear-gradient(0deg, #000000 0%, #dbdbbc 100%, #798daf 88%, #000000 100%);

    color : transparent;
    -webkit-background-clip : text;
    overflow : hidden;
    box-sizing : border-box;
    text-align : center;
  `,
  L_cont : styled.div`
    height : 100%;
    display : flex;
    flex-direction : column;
    justify-content : space-evenly;
    padding-right : 200px;
  `,
  R_cont : styled.div`
    height : 100%;
    display : flex;
    flex-direction : column;
    justify-content : space-evenly;
    padding-left : 50px;
  `,
  L_Title : styled.h2`
    font-size : 30px;
    font-weight : 700;
    line-height : 120%;
    letter-spacing : -0.003em;
    color : #ffffff;
    margin-bottom : 12px;
    margin-top : 0;
    text-align : end;
  `,
  Slide_main : styled.div`
    height : 100%;
    display : flex;
    flex-direction : column;
    justify-content : end;
  `,
  Slide_title : styled.div`
    font-size : 30px;
    font-weight : 800;
    font-family : 'Pretendard';
    line-height : 163%;
    color : #ffffff;
    text-align : start;
  `,
  Tags : styled.div`
  `,
  Tag : styled.div`
    border-radius : 36px;
    background : linear-gradient(135deg, #ffffff1a, #ffffff00);
    border : 1px solid #ffffff33;
  
    font-size : 16px;
    font-weight : 700;
    line-height: 120%;
    letter-spacing : -0.003em;
    color : #ffffff;
    filter : drop-shadow(0 3px 3px #00000040);

    display : inline-block;
    padding : 14px 20px;
    text-align : center;
    margin : 8px;
    white-space : nowrap;
    
  `,
  Logo : styled.img`
    width : 150px;
    height : 35px;
  `,
  Content : styled.p`
    text-align : end;
    font-weight : 500;
    font-size : 16px;
    line-height : 163%;
    letter-spacing : -0.001em;
    color : #ffffff;
    font-family : 'Pretendard';
  `,
  Bottom : styled.section`
    width : 100%;
    height : calc(var(--vh, 1vh) * 50 - 90px);

    display : flex;
    flex-direction : column;
    justify-content : center;
    align-items : start;
    overflow : hidden;
  `,
  Slide : styled.section`
    display : flex;
  `,
  Slide_item : styled.section`
    width : calc(100vw - 64px);
    display : flex;
    flex-direction : column;
  `
}
const Jikji_M = () => {

  const [scroll, setScroll] = useState(0);
  const tmp = useRef(null);
  
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      const newScroll = currentScrollY / (window.innerHeight * 3);
      setScroll((-(window.innerHeight * 8 + 360)/(window.innerHeight * 3)) + newScroll);
    };

    // 스크롤 이벤트 리스너 등록
    window.addEventListener('scroll', handleScroll);

    // 클린업 함수
    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const getShow = () => {
      const width = window.innerWidth - 64;
      let k = 0;
      if( scroll >= 0.2 && scroll < 0.4) {
        k = 1
      } else if (scroll >= 0.4 && scroll < 0.6) {
        k = 2
      } else if (scroll >= 0.6 && scroll < 0.8) {
        k = 3
      } else if (scroll >= 0.8) {
        k = 4
      }
      const translateY = -width * k;

      // 활성화 여부에 따라 transition을 조정하여 부드러운 입장과 퇴장을 설정
      const transition = 'opacity 1s ease-out, transform 1s ease-out';

      return {
          transform: `translateX(${translateY}px)`,
          transition: transition,
      };
  } 


  return (
    <S.Sticky ref={tmp}>
      <S.Jikji>

        <S.Top>
          <a style={{'width' : '100%', 'textAlign' : 'end'}} href="https://jikji.ai" target="_blank" rel="noreferrer noopener">
            <S.Logo src={logo_jikji} alt="logo"/>
            <img src={Next_W} style={{'marginLeft' : '20px'}} alt="next"/>
          </a>
          <S.Content>Based on the technologies of Al Network Platform where all Al devices are hyper-connected, We build the HPC environments that our customers desire.</S.Content>
        </S.Top>
        <S.Bottom>
          <S.Slide style={getShow()}>
            <S.Slide_item>
              <S.Slide_main>
                <S.Slide_title>HPC</S.Slide_title>
                <S.Slide_title>Workstation</S.Slide_title>
                <S.Slide_title>Server</S.Slide_title>
                <S.Slide_title>Single POD</S.Slide_title>
              </S.Slide_main>
            </S.Slide_item>
            <S.Slide_item>
            <S.L_Title style={{'textAlign' : 'center'}}>SOLUTION</S.L_Title>
              <S.Tags>
                <div> <S.Tag>Consulting</S.Tag> </div>
                <div><S.Tag>Cloud Operation</S.Tag></div>
                <div><S.Tag>Facility Design</S.Tag></div>
              </S.Tags>
            </S.Slide_item>
            <S.Slide_item>
              <S.L_Title style={{'textAlign' : 'center'}}>SW</S.L_Title>
              <S.Tags>
                <div><S.Tag>Infra Engineering</S.Tag></div>
                <div><S.Tag>Cloud Engineering</S.Tag></div>
                <div><S.Tag>AI Developer</S.Tag></div>
              </S.Tags>
            </S.Slide_item>
            <S.Slide_item>
              <S.L_Title style={{'textAlign' : 'center'}}>DATA CENTER</S.L_Title>
              <S.Tags>
                <div><S.Tag>Co-location</S.Tag></div>
                <div><S.Tag>Co-Premise</S.Tag></div>
                <div><S.Tag>Cloude Service</S.Tag></div>
              </S.Tags>
            </S.Slide_item>
            <S.Slide_item>
              <S.L_Title style={{'textAlign' : 'center'}}>INFRA</S.L_Title>
              <S.Tags>
                <div><S.Tag>Infra Engineering</S.Tag></div>
                <div><S.Tag>Facility Equipment</S.Tag></div>
              </S.Tags>
            </S.Slide_item>
          </S.Slide>
        </S.Bottom>
      </S.Jikji>
    </S.Sticky>
  )
}

export default Jikji_M;