import { Tech, Cubi, Contact, MainW, MainM, TechM, JikjiM, CubiM, Jikji2} from '.'
import { useEffect, useState } from "react";
import {styled} from 'styled-components';

const S = {
  text : styled.div`
    width : 100vw;
    height : 178px;

    font-size : 60px;
    font-weight : 800;
    color : #000000;
    line-height : 150%;
    display : flex;
    align-items : center;
    justify-content : center;

    overflow: hidden;
    white-space: nowrap;

    @media(max-width : 1024px) {
      font-size : 1.5em;
      height : 73px;
    }
  `
}
const Home = () => {

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);

  useEffect(() => {
    window.addEventListener('resize', setIsMobile(window.innerWidth < 1024));

    return () => window.removeEventListener('resize', setIsMobile(window.innerWidth < 1024))
  }, [])

  return (
    <>
      {isMobile ? 
        <>
          <MainM/>
          <S.text>인간과 인공지능의 새로운 인터페이스</S.text>
          <TechM/>
          <S.text>무한히 지속 가능한 인공지능 네트워크 플랫폼</S.text>
          <JikjiM/>
          <S.text>인공지능 차세대 디바이스</S.text>
          <CubiM/>
          <S.text>내 손 안의 인공지능</S.text>
          <Contact/>
        </>
        :
        <>
          <MainW/>
          <S.text>인간과 인공지능의 새로운 인터페이스</S.text>
          <Tech/>
          <S.text>무한히 지속 가능한 인공지능 네트워크 플랫폼</S.text>
          <Jikji2/>
          <S.text>인공지능 차세대 디바이스</S.text>
          <Cubi/>
          <S.text>내 손 안의 인공지능</S.text>
          <Contact/>
        </>
      }
    </>
  )
}

export default Home;