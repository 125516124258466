import { styled } from 'styled-components';
import {useRef, useState, useEffect} from 'react';
import { BG_cubi, Next_W, logo_cubi } from '../assets';

const S = {
  Sticky : styled.section`
    width : 100vw;
    height : 400vh;
    position : relative;
  `,
  Cubi : styled.section`
    width : 100vw;
    height : 100vh;
    font-family : 'Pretendard';

    position : sticky;
    top : 0;
    left : 0;
    z-index : 1;

    display : flex;
    flex-direction : column;
    justify-content : center;
    align-items : center;

    background : #171717;
    overflow : hidden;
    padding : 48px 32px;
    box-sizing : border-box;
  `,
  BG_img : styled.img`
    position : absolute;
    width : 150vw;
    left : -80%;
    top : 25%;
    z-index : -1;
    transform : scale(-1, 1);
  `,
  Aside : styled.section`
    width : 100%;
    height : 40%;
  `,
  Logo : styled.img`
    height : 85px;
  `,
  Logo_content : styled.span`
    text-align : start;
    font-size : 18px;
    font-weight : 500;
    line-height : 163%;
    letter-spacing : -0.001em;
    color : #ffffff;
    margin-top : 8px; 
  `,
  Logo_section : styled.div`
    display : flex;
    flex-direction : column;

  `,
  Menu_list : styled.div`
    width : 80%;
    margin-left : 20%;
    margin-top : 30%;
  `,
  Menu : styled.div`
    font-size : 30px;
    font-weight : 800;
    line-height : 163%;
    letter-spacing : -0.01em;
    color : white;
    text-align : end;
  `,
  S_Content : styled.section`
    width : 100%;
    height : 40%;
    position : absolute;
    bottom : 10%;

    padding : 0 32px;

    display : flex;
    flex-direction : column;
    justify-content : space-between;

    box-sizing : border-box;
  `,
  S_Cont : styled.p`
    font-size : 24px;
    font-weight : 400;
    line-height : 163%;
    letter-spacing : -0.01%;
    color : #ffffff;

    &.left {
      text-align : start;
    }
    &.right {
      text-align : end;
    }
  `,
  Homepage : styled.a`
    position : absolute;
    width : 100vw;

    font-size : 200px;
    background : linear-gradient(0deg, #000000 0%, #dbdbbc 100%, #798daf 88%, #000000 100%);

    color : transparent;
    -webkit-background-clip : text;

    cursor : pointer;
  `,
}

const Cubi_M = () => {

  const [scroll, setScroll] = useState(0);
  const tmp = useRef(null);
  
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      const newScroll = currentScrollY / (window.innerHeight * 3);
      setScroll((-(window.innerHeight * 12 + 540)/(window.innerHeight * 3)) + newScroll);
    };

    // 스크롤 이벤트 리스너 등록
    window.addEventListener('scroll', handleScroll);

    // 클린업 함수
    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const getShow = (baseScroll) => {

    // 스크롤 값에 따른 활성화 여부 결정
    const isActive = scroll > baseScroll && scroll < 0.6;

    // 활성화되지 않았을 때는 아래에서 시작
    const translateX = isActive ? 0 : -50; // 시작 위치 (아래에서 50px)
    
    // 활성화 상태에 따른 opacity 계산
    const opacity = isActive ? 1 : scroll < 0.6 ? 0.2 : 0;

    // 활성화 여부에 따라 transition을 조정하여 부드러운 입장과 퇴장을 설정
    const transition = 'opacity 1s ease-out, transform 1s ease-out';

    return {
        opacity: opacity,
        transform: `translateX(${translateX}px)`,
        transition: transition
    };
  } 

  const getShow2 = (baseScroll) => {

    // 스크롤 값에 따른 활성화 여부 결정
    const isActive = scroll > baseScroll;

    // 활성화되지 않았을 때는 아래에서 시작
    const translateX = isActive ? 0 : -50; // 시작 위치 (아래에서 50px)
    
    // 활성화 상태에 따른 opacity 계산
    const opacity = isActive ? 1 : 0;

    // 활성화 여부에 따라 transition을 조정하여 부드러운 입장과 퇴장을 설정
    const transition = 'opacity 1s ease-out, transform 1s ease-out';

    return {
        opacity: opacity,
        transform: `translateX(${translateX}px)`,
        transition: transition
    };
  } 

  const setLoca = () => {
    if (scroll >= 0.6 ){
      return {
        left : '50%',
        top : '25%',
        transition: 'left 0.5s ease-in-out'
      }
    } else {
      return {
        left : '-80%',
        top : '25%',
        transition: 'left 0.5s ease-in-out'
      }

    }
  }
  return (
    <S.Sticky ref={tmp}>
      <S.Cubi>
        <S.BG_img src={BG_cubi} alt="background" style={setLoca()}/>
          <S.Aside>
            <S.Logo_section>
              {/* <a style={{'width' : '100%', 'textAlign' : 'start'}} href="https://jikji.ai" target="_blank" > rel="noreferrer noopener" */}
              <div style={{'width' : '100%', 'textAlign' : 'start'}}>
                <S.Logo src={logo_cubi}/>
                <img src={Next_W} style={{'marginLeft' : '20px'}} alt="next"/>
              </div>
              <S.Logo_content>Cubi creates a world where AI understands my life and lives and breathes together.</S.Logo_content>
            </S.Logo_section>
          </S.Aside>
          <S.Aside>
            <S.Menu_list>
              <S.Menu style={getShow(0.2)}>LLM</S.Menu>
              <S.Menu style={getShow(0.3)}>On Device AI</S.Menu>
              <S.Menu style={getShow(0.4)}>Generative AI</S.Menu>
            </S.Menu_list>
          </S.Aside>

          <S.S_Content style={getShow2(0.6)}>
            <S.S_Cont className='left'><strong>We create devices</strong><br/>that are located<br/>closest to consumers</S.S_Cont>
            <S.S_Cont className='right'>We create the most<br/>suitable and outstanding<br/><strong>AI models.</strong></S.S_Cont>
          </S.S_Content>

      </S.Cubi>
    </S.Sticky>
  )
}

export default Cubi_M;