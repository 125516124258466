import React, {useRef } from "react";
import {keyframes, styled} from 'styled-components'
import { Exit } from "../assets";
import emailjs from '@emailjs/browser';

const vibration = keyframes`
  from {
    transform : rotate(1deg);
  }
  to {
    transform : rotate(-1deg);
  }
`

const S = {
  Modal : styled.div`
    width : 100vw;
    height : 100vh;
    box-sizing : border-box;
    padding : 5vh 10vw;
    position : fixed;
    z-index : 998;
    top : 0;

    display : flex;
    flex-direction : column;
    justify-content : center;
  `,
  Header : styled.section`
    padding-bottom : 20px;
    display : flex;
    justify-content : flex-end;
  `,
  Body : styled.div`
    display : flex;
    width: 100%;
    max-width : 1160px;
    height : calc(100% - 50px);
    font-family : 'Pretendard';
  `,
  Content : styled.form`
    width : 100%;
    height : 100%;
    display : flex;
    background : #ffffff;
    box-sizing : border-box;
    border-radius : 32px;
    color : #000000;
  `,
  Left : styled.section`
    width : 60%;
    padding : 40px 50px;
  `,
  Aside : styled.div`
    width : 40%;
    height : calc(100% - 80px);
    margin-right : -30px;
    border-radius : 0 32px 32px 0;
    padding : 40px 50px;
    background : #f5f5f5;

    display : flex;
    flex-direction : column;
    justify-content : space-between;
  `,
  Grid : styled.div`
    display : grid;
    grid-template-rows : 1fr 1fr 1fr 5fr;
    grid-gap : 20px;
    height : calc(100% - 80px);
    flex-grow : 1;
  `,
  Title : styled.div`
    font-family : 'GosanjaOTF';
    font-size : 30px;
    margin-bottom : 21px;

    display : flex;

    & hr {
      margin-left : 30px;
      flex-grow : 1;
      height : 0px;
    }
  `,
  Subtitle : styled.div`
    font-size : 20px;
    margin-bottom : 10px;
    text-align : start;
    font-weight: 700;
    color : #999999;
  `,
  BusinessGroup : styled.div`
  `,
  
  InputGroup : styled.div`
    display : flex;
  `,
  Name : styled.div`
    width : 50%;
    margin-right : 5px;

    & > input {
      width : calc(100% - 40px);
    }
  `,
  Email : styled.div`
    width : 50%;
    margin-left : 5px;

    & > input {
      width : calc(100% - 40px);
    }
  `,
  Input : styled.input`
    font-size : 16px;
    font-family : 'Pretendard';
    height : 51px;
    padding : 0 20px;

  `,
  MessageGroup : styled.div`
    display : flex;
    flex-direction : column;
  `,
  Message : styled.input.attrs(props => ({type : 'text'}))`
    resize : none;
    width : 100%;
    flex-grow : 1;

    font-size : 16px;
    font-family : 'Pretendard';
    padding : 20px;
    box-sizing : border-box;
  `,
  Description : styled.div`
    font-size : 14px;
    color : #999999;
    line-height : 150%;
    word-break : keep-all;
    margin-top : 65px;
    text-align : start;
  `,
  Desc_Title : styled.div`
    font-weight : 700;
    font-size : 16px;
    margin : 10px 0;
  `,
  CheckBoxWrapper : styled.label`
    display : flex;
    width : 350px;
    margin : 20px auto 0;
    cursor : pointer;

    &.vibration {
      animation : ${vibration} 0.1s;
      animation-iteration-count : 3;
    }

    &.product {
      width : 80px;
    }
  `,
  CheckBoxWrapperProduct : styled.label`
    display : flex;
    margin-right : 30px;

  `,
  CheckBoxInput : styled.input`
    appearance : none;
    width : 30px;
    height : 30px;
    border : 1px solid #171717;
    border-radius : 2px;
    background-clip : content-box;
    margin-right : 9px;
    cursor : pointer;

    &:checked {
      padding : 5px;
      background-color : #171717;
      border-radius : 1px;

    }
  `,
  CheckBoxLabel : styled.div`
    font-size : 14px;
    line-height : 130%;
    width : calc(100% - 40px);
    word-break : keep-all;
    text-align : start;

    display : flex;
    align-items : center;

    &.product {
      font-size : 16px;
    }
  `,
  Button : styled.button.attrs(props => ({type : 'submit'}))`
    width : 350px;
    height : 64px;
    background-color : #171717;
    color : #ffffff;
    font-size : 20px;
    font-family : 'GosanjaOTF';
    cursor : pointer;
    margin : 20px auto 0;
  `,
  Business : styled.div`
    display : flex;
    justify-content : space-between;
  `,
  RadioInput : styled.div`
    width : 30%;
    height : 51px;
    border : 1px solid #999999;
    text-align : center;
    background-color : #f5f5f5;
    color : #999999;
    cursor : pointer;
    line-height : 51px;
    font-size : 16px;
    
    &.checked {
      background-color : #ffffff;
      color : #171717;
      border-color : #171717;
      font-weight : 700;
    }
  `,
  Radio_label : styled.label`
    width : 30%;
    height : 51px;
    text-align : center;
    cursor : pointer;
    line-height : 51px;
    font-size : 16px;
  `,

  Radio : styled.input.attrs(props => ({type : 'radio'}))`
    display : none;

    & ~ label {
      background-color : #f5f5f5;
      color : #999999;
      border : 1px solid #999999;
    }

    &:checked + label {
      background-color : #ffffff;
      color : #171717;
      border : 1px solid #171717;
      font-weight : 700;
    }
  `,
  Exit : styled.div`
    background : no-repeat center/contain url(${Exit});
    width : 30px;
    height : 30px;
    cursor:pointer;
  `,

  ProductGruop : styled.div`
  `
}


const GetInTouch=({closeModal}) => {

  const formRef = useRef();

  const close = () => {
    closeModal();
  }

  const submit = (e) => {
    const check = document.getElementById('checkwrapper');
    const checkbtn = document.getElementById('checkinput');

    if (checkbtn.checked) {
      postContact(e)
    }
    else {
      check.classList.add("vibration");
      setTimeout(() => {
        check.classList.remove("vibration");
      }, 1000);
    }
  }

  const postContact = () => {
    emailjs.sendForm("service_soinfj4", "template_uwzv92f", formRef.current, "19LP0E-gjU_jO_c75" ).then(
      result => {
        alert("성공적으로 전송하였습니다.");
        formRef.current.reset();
        close()
      },
      error => {
        console.log(error.text);
        alert("전송이 실패되었습니다.")
      }
    )
  }



  return (
    <S.Modal>
      <S.Header>
        <S.Exit onClick={() => close()}/>
      </S.Header>
      <S.Body>
        <S.Content onSubmit={submit} ref={formRef}>
          <S.Left>
            <S.Title>
              <span>GET IN TOUCH</span>
            </S.Title>
            <S.Grid>
              <S.BusinessGroup>
                <S.Subtitle>Choose your business</S.Subtitle>
                <S.Business>
                  <S.Radio name='business' id="sales" value="Sales" defaultChecked/><S.Radio_label for="sales">Sales</S.Radio_label>
                  <S.Radio name='business' id="ir" value="IR" /><S.Radio_label for="ir">IR</S.Radio_label>
                  <S.Radio name='business' id="partnership" value="Partnership"/><S.Radio_label for="partnership">Partnership</S.Radio_label>
                </S.Business>
              </S.BusinessGroup>
              <S.InputGroup>
                <S.Name>
                  <S.Subtitle>Your name</S.Subtitle>
                  <S.Input name="from_name"/>
                </S.Name>
                <S.Email>
                  <S.Subtitle>E-mail</S.Subtitle>
                  <S.Input name="email"/>
                </S.Email>
              </S.InputGroup>
              <S.ProductGruop>
                <S.Subtitle>Product</S.Subtitle>
                <div style={{'display' : 'flex'}}>
                  <S.CheckBoxWrapperProduct>
                    <S.CheckBoxInput type="checkbox" id='product_jikji'/>
                    <S.CheckBoxLabel className="product">JIKJI</S.CheckBoxLabel>
                  </S.CheckBoxWrapperProduct>
                  <S.CheckBoxWrapperProduct>
                    <S.CheckBoxInput type="checkbox" id='product_cubi'/>
                    <S.CheckBoxLabel className="product">CUBI</S.CheckBoxLabel>
                  </S.CheckBoxWrapperProduct>
                </div>
              </S.ProductGruop>
              <S.MessageGroup>
                <S.Subtitle>Message</S.Subtitle>
                <S.Message name="message" placeholder="내용을 입력하세요.(필수)"/>
              </S.MessageGroup>
              
            </S.Grid>
          </S.Left>
        

          <S.Aside>
            <S.Description>
              <S.Desc_Title>
                개인정보의 수집 및 이용에 관한 사항
              </S.Desc_Title>
              아래 수집하는 개인정보는 이용자의 문의에 대한 답변을 위해 요구되는 정보로
              정보의 최대 보유 기간은 수집 후 1년입니다. <br/> <br/>
              문의 사항에 대한 답변을 받기를 원한다면
              해당 서비스를 이용시 동의를 하신 후 서비스를 이용하실 수 있습니다.
              <br/><br/>
              - 필수 정보: 성명, 전화번호, 이메일
            </S.Description>
            <div style={{'textAlign' : 'center'}}>
              <S.CheckBoxWrapper id='checkwrapper'>
                <S.CheckBoxInput type="checkbox" id='checkinput'/>
                <S.CheckBoxLabel>
                  [개인정보 수집/이용 등에 관한 사항]을 잘 읽어보았으며 내용에 동의합니다.
                </S.CheckBoxLabel>
              </S.CheckBoxWrapper>
              <S.Button>SUBMIT</S.Button>
            </div>
          </S.Aside>
        </S.Content>
      </S.Body>
    </S.Modal>
  )
}

export default GetInTouch;
