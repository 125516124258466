import {styled} from "styled-components";
import { BG } from "../assets";
import { useEffect, useRef, useState } from "react";

const S = {
  Sticky : styled.section`
    width : 100vw;
    height : 400vh;
    position : relative;
  `,
  Home : styled.section`
    width : 100vw;
    height : calc(var(--vh, 1vh) * 100);
    background : no-repeat center/cover url(${BG});
    font-family : 'GosanjaOTF';

    position : sticky;
    top : 0;
    left : 0;
    z-index : 1;

    display : flex;
    justify-content : center;
    align-items : center;
  `,
  Title : styled.h1`
    font-size : 84px;
    line-height : 150%;
    letter-spacing : -0.01em;
    margin : 10px 0 0;

    background-image: linear-gradient(90deg, #DBDBBC 0%, #798DAF 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  `,
  SubTitle : styled.h3`
    font-size : 32px;
    line-height : 150%;
    letter-spacing : -0.01em;
    margin : 0;
    color : #ffffff;
  `,
  Title_sec : styled.section`
    
  `,

  Star : styled.div`
    width : 70vw;
    height : 70vh;
    position : absolute;
    top : 30vh;
    left : 0;
  `,

  Text : styled.section`
    position : absolute;
    top : 10vh;
    left : 0;

    width : 100vw;
    height : 80vh;

  `,
  Text_item : styled.div`
    display : flex;
    align-items : end;
    position : absolute;
    text-align : start;

    &.first {
      top : calc(24vh - 75px);
      left : calc(26vw - 200px);
      flex-direction : row-reverse;
      text-align : end;
    }

    &.second {
      top : 68.5vh;
      left : calc(35vw - 300px);
      flex-direction : row-reverse;
      text-align : end;
    }

    &.third {
      top : calc(19vh - 35px);
      left : calc(63.5vw + 30px);
    }

    &.forth {
      top : calc(64vh);
      left : calc(69vw);
    }
  `,
  Text_title : styled.div`
    font-size : 3em;
    line-height : 150%;
    letter-spacing : -0.01em;
    color : #ffffff;
  `,
  Text_content : styled.div`
    font-size : 0.7rem;
    line-height : 150%;
    color : #ffffff;
    font-family : 'Pretendard';
    margin : 0 22px 15px;
  `,

  Canvas : styled.canvas`
    position : absolute;

    width : 100vw;
    height : 80vh;

    z-index : -1;
  `
}

const Main_W = () => {
  const [scroll, setScroll] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      const newScroll = currentScrollY / (window.innerHeight * 3);
      setScroll(newScroll);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
  }, [scroll])

  const getShowText = (baseScroll, state="show") => {

    if (state === 'hide') {
      // 스크롤 값에 따른 활성화 여부 결정
      const isActive = scroll < baseScroll;
      
      // 활성화 상태에 따른 opacity 계산
      const opacity = isActive ? 1 : 0;
  
      // 활성화 여부에 따라 transition을 조정하여 부드러운 입장과 퇴장을 설정
      const transition = 'opacity 1s ease-out, transform 1s ease-out';
  
      return {
          opacity: opacity,
          transition: transition
      };
    } else if (state === 'show') {  
      // 스크롤 값에 따른 활성화 여부 결정
      const isActive = scroll > baseScroll;
      
      // 활성화 상태에 따른 opacity 계산
      const opacity = isActive ? 1 : 0;
  
      // 활성화 여부에 따라 transition을 조정하여 부드러운 입장과 퇴장을 설정
      const transition = 'opacity 1s ease-out, transform 1s ease-out';
  
      return {
          opacity: opacity,
          transition: transition
      };
    }
  }

  const getShow = (baseScroll) => {
    // 스크롤 값에 따른 활성화 여부 결정
    const isActive = scroll > baseScroll && scroll < baseScroll+0.2;
    
    // 활성화 상태에 따른 opacity 계산
    const opacity = isActive ? 1 : 0;

    // 활성화 여부에 따라 transition을 조정하여 부드러운 입장과 퇴장을 설정
    const transition = 'opacity 1s ease-out, transform 1s ease-out';

    return {
        opacity: opacity,
        transition: transition
    };
  }

  const canvasRef1 = useRef();
  const canvasRef2 = useRef();
  const canvasRef3 = useRef();
  const canvasRef4 = useRef();

  //원 그리는 로직
  function drawArc(x, y, context) {
    context.beginPath();
    context.strokeStyle = '#ffffff';
    context.fillStyle = '#ffffff';
    context.arc(x, y, 6, 0, Math.PI*2)
    context.fill()
    context.stroke()
  }

  function drawLine(x1, y1, x2, y2, context) {
    context.beginPath();
    context.moveTo(x1, y1);
    context.lineTo(x2, y2);
    context.strokeStyle = '#ffffff';
    context.fillStyle = '#ffffff';
    context.stroke()
  }

  useEffect(() => {

    const arclist = [[0.26, 0.24], [0.37, 0.685], [0.635, 0.19], [0.69, 0.64]];
    const width = window.innerWidth;
    const height = window.innerHeight;

    const canvas1 = canvasRef1.current;
    canvas1.width = window.innerWidth;
    canvas1.height = window.innerHeight * 0.8;
    const context1 = canvas1?.getContext('2d');

    drawArc(width * arclist[0][0], height * arclist[0][1], context1)
    drawLine(width * arclist[0][0], height * arclist[0][1], width * 0.5, height * 0.4, context1)

    const canvas2 = canvasRef2.current;
    canvas2.width = window.innerWidth;
    canvas2.height = window.innerHeight * 0.8;
    const context2 = canvas2?.getContext('2d');

    drawArc(width * arclist[0][0], height * arclist[0][1], context2)
    drawArc(width * 0.37, height * 0.685, context2)
    drawLine(width * arclist[0][0], height * arclist[0][1], width * 0.5, height * 0.4, context2)
    drawLine(width * arclist[0][0], height * arclist[0][1], width * 0.37, height * 0.685, context2)
    
    const canvas3 = canvasRef3.current;
    canvas3.width = window.innerWidth;
    canvas3.height = window.innerHeight * 0.8;
    const context3 = canvas3?.getContext('2d');

    drawArc(width * arclist[0][0], height *arclist[0][1], context3)
    drawArc(width * arclist[1][0], height * arclist[1][1], context3)
    drawArc(width * arclist[2][0], height * arclist[2][1], context3)
    drawLine(width * arclist[0][0], height * arclist[0][1], width * 0.5, height * 0.4, context3)
    drawLine(width * arclist[0][0], height * arclist[0][1], width * arclist[1][0], height * arclist[1][1], context3)
    drawLine(width * arclist[2][0], height * arclist[2][1], width * arclist[1][0], height * arclist[1][1], context3)
    
    const canvas4 = canvasRef4.current;
    canvas4.width = window.innerWidth;
    canvas4.height = window.innerHeight * 0.8;
    const context4 = canvas4?.getContext('2d');

    drawArc(width * arclist[0][0], height * arclist[0][1], context4)
    drawArc(width * arclist[1][0], height * arclist[1][1], context4)
    drawArc(width * arclist[2][0], height * arclist[2][1], context4)
    drawArc(width * arclist[3][0], height * arclist[3][1], context4)
    drawLine(width * arclist[0][0], height * arclist[0][1], width * arclist[3][0], height * arclist[3][1], context4)
    drawLine(width * arclist[0][0], height * arclist[0][1], width * arclist[1][0], height * arclist[1][1], context4)
    drawLine(width * arclist[2][0], height * arclist[2][1], width * arclist[1][0], height * arclist[1][1], context4)
    drawLine(width * arclist[2][0], height * arclist[2][1], width * arclist[3][0], height * arclist[3][1], context4)

  }, [])

  return (
    <S.Sticky>
      <S.Home >
        <S.Title_sec>
          <S.SubTitle>단순히 팔로워가 아닌 새로운 이야기의 주인공</S.SubTitle>
          <S.Title>Stars call to us</S.Title>
        </S.Title_sec>

        <S.Text>
          <S.Text_item className="first" style={getShowText(0.2)}>
            <S.Text_title>NEW</S.Text_title>
            <S.Text_content>인간과 인공지능의<br/>새로운 인터페이스</S.Text_content>
          </S.Text_item>
          <S.Text_item className="second" style={getShowText(0.4)}>
            <S.Text_title>NEXT</S.Text_title>
            <S.Text_content>인공지능<br/>차세대 디바이스</S.Text_content>
          </S.Text_item>
          <S.Text_item className="third" style={getShowText(0.6)}>
            <S.Text_title>INFINITE</S.Text_title>
            <S.Text_content>무한히 지속 가능한<br/>인공지능 네트워크 플랫폼</S.Text_content>
          </S.Text_item>
          <S.Text_item className="forth" style={getShowText(0.8)}>
            <S.Text_title>NATURAL</S.Text_title>
            <S.Text_content>내 손 안의 인공지능</S.Text_content>
          </S.Text_item>
        </S.Text>

        <S.Canvas ref={canvasRef1} style={getShow(0.2)}/>
        <S.Canvas ref={canvasRef2} style={getShow(0.4)}/>
        <S.Canvas ref={canvasRef3} style={getShow(0.6)}/>
        <S.Canvas ref={canvasRef4} style={getShow(0.8)}/>
      </S.Home>
    </S.Sticky>
  )
}

export default Main_W;