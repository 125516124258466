import { styled } from 'styled-components';
import {useRef, useState, useEffect} from 'react';
import { BG_cubi, Next_main, logo_cubi } from '../assets';

const S = {
  Sticky : styled.section`
    width : 100vw;
    height : 400vh;
    position : relative;
  `,
  Cubi : styled.section`
    width : 100vw;
    height : 100vh;
    font-family : 'Pretendard';

    position : sticky;
    top : 0;
    left : 0;
    z-index : 1;

    display : flex;
    justify-content : center;
    align-items : center;

    background : #000000;
    overflow : hidden;
    
  `,
  BG_img : styled.img`
    position : absolute;
    width : 70vw;
    left : -10%;
    top : -10%;
    z-index : -1;
  `,
  Aside : styled.section`
    width : 50%;
    height : 100%;
  `,
  Logo : styled.img`
    width : 257px;
    height : 118px;
  `,
  Logo_content : styled.span`
    text-align : start;
    font-size : 18px;
    line-height : 163%;
    letter-spacing : -0.001em;
    color : #ffffff;
    margin-top : 33px; 
  `,
  Logo_section : styled.div`
    display : flex;
    flex-direction : column;
    width : 406px;
    margin : 177px auto 0;

    &.hidden {
      visibility : hidden;
    }
  `,
  Menu_list : styled.div`
    width : 80%;
    height : 100%;
    margin-left : 20%;
    box-sizing : border-box;

    display : flex;
    flex-direction : column;
    justify-content : end;
    padding-bottom : 5vh;
  `,
  Menu : styled.div`
    font-size : 6vw;
    font-weight : 800;
    line-height : 163%;
    letter-spacing : -0.01em;
    color : white;
    text-align : start;
    white-space : nowrap;
  `,
  S_Content : styled.section`
    width : 100vw;
    height : 50vh;
    position : absolute;
    top : 50vh;

    display : flex;
    justify-content : space-between;

    padding : 0 127px;
    box-sizing : border-box;
  `,
  S_Cont : styled.p`
    font-size : 3.5vw;
    font-weight : 800;
    line-height : 163%;
    letter-spacing : -0.01%;
    color : #ffffff;

    &.left {
      text-align : start;
    }
    &.right {
      text-align : end;
    }
  `,
  Homepage : styled.a`
    position : absolute;
    width : 100vw;

    font-size : 200px;
    background : linear-gradient(0deg, #000000 0%, #dbdbbc 100%, #798daf 88%, #000000 100%);

    color : transparent;
    -webkit-background-clip : text;

    cursor : pointer;
  `,
  Next_icon : styled.img`
    width : 161px;
    height: 161px;
    margin-left : 26px;
  `
}

const Cubi = () => {

  const [scroll, setScroll] = useState(0);
  const tmp = useRef(null);
  
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      const newScroll = currentScrollY / (window.innerHeight * 3);
      setScroll((-(window.innerHeight * 12 + 540)/(window.innerHeight * 3)) + newScroll);
    };

    // 스크롤 이벤트 리스너 등록
    window.addEventListener('scroll', handleScroll);

    // 클린업 함수
    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  const getShow = (baseScroll) => {

    // 스크롤 값에 따른 활성화 여부 결정
    const isActive = scroll > baseScroll && scroll < 0.6;

    // 활성화되지 않았을 때는 아래에서 시작
    const translateX = isActive ? 0 : -50; // 시작 위치 (아래에서 50px)
    
    // 활성화 상태에 따른 opacity 계산
    const opacity = isActive ? 1 : scroll < 0.6 ? 0.2 : 0;

    // 활성화 여부에 따라 transition을 조정하여 부드러운 입장과 퇴장을 설정
    const transition = 'opacity 1s ease-out, transform 1s ease-out';

    return {
        opacity: opacity,
        transform: `translateX(${translateX}px)`,
        transition: transition
    };
  } 

  const getShow2 = (baseScroll) => {

    // 스크롤 값에 따른 활성화 여부 결정
    const isActive = scroll > baseScroll && scroll < 0.8;

    // 활성화되지 않았을 때는 아래에서 시작
    const translateX = isActive ? 0 : -50; // 시작 위치 (아래에서 50px)
    
    // 활성화 상태에 따른 opacity 계산
    const opacity = isActive ? 1 : 0;

    // 활성화 여부에 따라 transition을 조정하여 부드러운 입장과 퇴장을 설정
    const transition = 'opacity 1s ease-out, transform 1s ease-out';

    return {
        opacity: opacity,
        transform: `translateX(${translateX}px)`,
        transition: transition
    };
  } 

  const getShow3 = (baseScroll) => {

    // 스크롤 값에 따른 활성화 여부 결정
    const isActive = scroll > baseScroll;

    const cursor = isActive ? 'pointer' : 'default';

    // 활성화되지 않았을 때는 아래에서 시작
    const translateX = isActive ? 0 : -50; // 시작 위치 (아래에서 50px)
    
    // 활성화 상태에 따른 opacity 계산
    const opacity = isActive ? 1 : 0;

    // 활성화 여부에 따라 transition을 조정하여 부드러운 입장과 퇴장을 설정
    const transition = 'opacity 1s ease-out, transform 1s ease-out';

    return {
        opacity: opacity,
        transform: `translateX(${translateX}px)`,
        transition: transition,
        cursor : cursor
    };
  } 

  const getLoca = () => {
    if (scroll >= 0.8) {
      return {
        left : '30%',
        top : '20%',
        width : '30vw',
        transition: 'top 0.5s ease-in-out'
      }
    } else if (scroll >= 0.6) {
      return {
        left : '10%',
        top : '50%',
        transition: 'left 0.5s ease-in-out, top 0.5s ease-in-out'
      }
    } else {
      return {
        left : '-10%',
        top : '-10%',
        transition: 'left 0.5s ease-in-out, top 0.5s ease-in-out'
      }
    }
  }

  return (
    <S.Sticky ref={tmp}>
      <S.Cubi>
        <S.BG_img src={BG_cubi} style={getLoca()} alt="background"/>
        <S.Aside>
          <S.Logo_section className={scroll < 0.8 ? 'show' : 'hidden'}>
            <S.Logo src={logo_cubi} alt="logo"/>
            <S.Logo_content>Cubi creates a world where AI understands my life <br/>and lives and breathes together.</S.Logo_content>
          </S.Logo_section>
        </S.Aside>
        <S.Aside>
          <S.Menu_list>
            <S.Menu style={getShow(0.2)}>LLM</S.Menu>
            <S.Menu style={getShow(0.3)}>On Device AI</S.Menu>
            <S.Menu style={getShow(0.4)}>Generative AI</S.Menu>
          </S.Menu_list>
        </S.Aside>

        <S.S_Content style={getShow2(0.6)}>
          <S.S_Cont className='left'>We create devices<br/>that are located<br/>closest to consumers</S.S_Cont>
          <S.S_Cont className='right'>We create the most<br/>suitable and outstanding<br/>AI models.</S.S_Cont>
        </S.S_Content>
        {/* cubi 링크 연결 */}
        <S.Homepage style={getShow3(0.8)}>
          GO CUBI
          <S.Next_icon src={Next_main} alt="next"/>
        </S.Homepage>
      </S.Cubi>
    </S.Sticky>
  )
}

export default Cubi;