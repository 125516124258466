import { VI1, VI2, VI3, DI1, DI2, OI1, OI2, DV1, DV2, DV3, VIM1, VIM2, VIM3, VIM4, DIM1, DIM2, OIM1, OIM2, OIM3, DVM1, DVM3, DVM2, OIM4 } from "../assets";

const data = {
  'VIDEO INTELLIGENCE' : [
    {
      title : 'POD (Pixel On Demand):영상 전송 기술',
      img : VI1,
      m_img1 : VIM1,
      m_img2 : VIM2,
      img_loca : 'right',
      column : false,
      content : [
        "POD는 영상 데이터를 효율적으로 전송하고 처리하는 지능형 기술로, 네트워크 증설 없이 다수의 영상 데이터를 전송하는 알고리즘입니다.\nPOD는 최종 디스플레이되는 영상 사이즈에 맞추어 필요한 영상이나 이미지만 전송합니다. 화면에 표출되는 영상 개수, Zoom level에 맞춰 Adaptive하게 비디오와 이미지 양을 자동 조절하여 스트리밍 하기 때문에 네트워크 대역폭 사용량도 획기적으로 줄일 수 있습니다.",
        "그 결과 CPU, 메모리 등 시스템 자원과 네트워크 부하량도 줄여 낭비되는 컴퓨팅 자원도 최소화할 수 있습니다.\nPOD는 영상개수만큼 네트워크를 증설해야 했던 비용 장벽을 허물고, 이전에 보지 못했던 원격지 영상 데이터를 자유롭게 보게 만든 기술로 특허로 보호받고 있습니다."
      ]
    },
    {
      title : 'VIDEO INTELLIGENCE: 영상정보 지능화 기술',
      img : VI2,
      m_img1 : VIM3,
      img_loca : 'top',
      column :  true,
      content : [
        "엔쓰리엔은 수집된 영상의 소스에 따라 RULE BASED 방식과 DEEP LEARNING 방식을 선택적으로 적용하는 하이브리드 방식의 영상분석 알고리즘을 적용하여 95% 이상의 정확도를 가지고 있습니다. RULE BASED 방식은 최초 시장에 소개된 이후 꾸준하게 품질이 향상되어 복잡도가 낮은 분야에서는 높은 정확도를 보여줍니다. 만약 복잡도가 높은 분야일 경우 DEEP LEARNING 방식을 적용하여, 보다 정확한 영상 분석 결과를 도출합니다.",
        "하이브리드 알고리즘 방식은 불필요하게 소모되는 컴퓨팅 자원을 최소화하는 동시에, 실시간으로 결과 도출이 필요한 분야에 효율적으로 적용할 수 있습니다. 엔쓰리엔의 VIDEO INTELLIGENCE ENGINE은 PoD 기술과 더불어 클라우드 서버는 물론 EDGE COMPUTING 분야에도 적용될 수 있도록 패키징 되어 있습니다엔쓰리엔의 제품들은 공통 백엔드를 기반으로, 추후 팩토리나우와 같은 특정분야 맞춤형 서비스가 지속적으로 추가됩니다. 비즈니스에서 수집되는 어떤 데이터든 엔쓰리엔의 서비스를 통해 생생한 인사이트를 획득해 성공적인 비즈니스를 운영하십시오."
      ]
    },
    {
      title : 'RDS (Remote Desktop System) : 원격 PC 화면 전송 장비',
      img : VI3,
      m_img1 : VIM4,
      img_loca : 'top',
      content : [
        "#소프트웨어 설치 X  #Local Network 접속 X  #Delay X  #거리제한 X  #USB, Serial 케이블만으로 제어가능 #관리비용절약",
        "RDS는 원격 장비 화면을 영상화(하나의 CCTV로 인식)하여 전송해주는 엔쓰리엔의 장비입니다. SCADA, MES 등 공장의 주요 장비나 시스템은 로컬 PC에만 연결되어 외부에서 접근이 불가능한 경우가 많습니다. RDS는 원격 기기에 간단한 VIDEO 케이블 연결만으로 원격 기기의 영상을 비디오 스트리밍형태로 전송합니다.",
        "원격 서버 또한 연결이 가능해, 모든 원격 기기의 상태를 실시간 영상으로 확인할 수 있고, 일부 기능은 제어까지 가능합니다. RDS는 CCTV만으로 부족할 수 있는 현장의 각 운영 시스템 정보를 RDS를 통해 수집함으로써 완벽한 현장 상태를 파악할 수 있는 EDGE COMPUTING DEVICE입니다."
      ]
    }
  ],
  'DATA INTELLIGENCE' : [
    {
      title : 'DTG (Dynamic Topology Generator): 데이터 구조화 기술',
      img : DI1,
      m_img1 : DIM1,
      content : [
        "DTG는 데이터 간의 연관 및 계층관계를 정의하면, 그 Rule에 맞게 자동으로 논리 계층 지도를 생성해 화면으로 표현하는 알고리즘입니다.\n비즈니스 프로세스 혹은 IT 자원들간 관계가 변경될 경우 일일이 수정하지 않아도 자동으로 지도를 생성하는 핵심기술입니다.",
        "데이터간 구조가 논리적으로 계층화되면, 데이터 흐름을 직관적으로 파악할 수 있고,\n데이터의 물리적 위치, 시간별 상황, 이상 발생시 영향 범위 등을 지도 상에서 직관적으로 알 수 있습니다.\n이를 통해 문제발생시 문제발생시간(MTTD, Mean Time To Detect), 문제대응시간(MTTR, Mean Time To Resolve)을 획기적으로 줄일 수 있습니다.\n뿐만 아니라 장애 발생시 수작업으로 작성하던 보고서를 작성하던 절차도 DTG 화면으로 대체할 수 있어, 운영 시스템 전반의 혁신을 가져왔습니다."
      ]
    },
    {
      title : 'DATA PLATFORM',
      img : DI2,
      m_img2 : DIM2,
      content : [
        "엔쓰리엔의 데이터 플랫폼은 정형/비정형 데이터를 모두 수집/정제/통합/저장/분석될 수 있도록 독자적으로 개발된 빅데이터 엔진입니다.\n다양한 종류의 빅데이터 엔진과 연동될 뿐 아니라, 엔쓰리엔의 프로젝트 경험에서 축적된 수많은 machine language를 라이브러리 형태로 보유하고 있습니다.\n또한 엔쓰리엔의 시각화 방법론에 최적화되어, 단기간 내 다양한 데이터를 표출할 수 있습니다."
      ]
    }
  ],
  'OPERATION INTELLIGENCE' : [
    {
      title : 'SOP (Standard Operation Procedure): 표준매뉴얼 기반 대응체계',
      img : OI1,
      m_img1 : OIM1,
      m_img2 : OIM2,
      content : [
        "엔쓰리엔의 솔루션은 자연재해, 화재, 폭발 등 다양한 위기상황에 대응하기 위한 표준대응 매뉴얼을 솔루션 화면상에서 직접 표출, 관리할 수 있습니다.",
        "이를 통해 모니터링 운영자는 화면을 이탈하지 않고도, 신속하게 상황에 대처할 수 있습니다.\nAlert 기능과 연계할 경우 자동으로 SOP가 화면에 표출되어 운영자 누구라도 쉽게 상황에 대처할 수 있어, 시스템 중심의 비상 대응이 가능해 집니다."
      ]
    },
    {
      title : ['SMART ALERT', 'OPERATION PLAYBACK'],
      img : OI2,
      m_img2 : OIM3,
      m_img3 : OIM4,
      content : [
        "설정한 임계치를 초과해 ALERT이 발생할 경우, 엔쓰리엔은 문제 발생시 영향을 받는 프로세스와 그 범위를 구조적으로 보여줍니다.\n문제가 발생한 곳으로 단계적으로 접근하는 과정을 통해, 운영자 및 조직의 구성원들은 전체 공정을 자연스레 이해하게 되어 신속한 상황 대처가 가능해집니다.",
        "엔쓰리엔은 과거 시점으로 이동해 특정 시점의 영상과 데이터를 재생해 볼 수 있는 기능을 제공합니다. 이를 통해 문제가 발생한 정확한 시점 및 그 원인을 입체적으로 파악할 수 있으며, 업무 효율성을 극대화할 수 있습니다."
      ]
    }
  ],
  'DATA VISUALIZATION' : [
    {
      title : 'LOCATION BASED VISUALIZATION',
      img : DV1,
      m_img1 : DVM1,
      content : [
        '엔쓰리엔은 구조화된 데이터를 수집한 실제 지도 위에서 이종 데이터(표, 차트, 영상 등)를 표현합니다.\n데이터 수치와 영상을 지도, 건물, 층별 구성도, 공정 등 실제 위치에 기반하여 시각화할 경우, 운영자는 직관적으로 데이터의 의미를 이해할 수 있습니다.\n또한 물리적 위치를 논리적 위치로 변환할 경우 각 데이터간의 관계가 명확하게 나타납니다. 이를 통해 데이터를 종합적으로 이해하고, 신속한 문제 대응이 가능해집니다.'
      ]
    },
    {
      title : 'MULTI LATER & ZOOMABLE UI',
      img : DV2,
      m_img1 : DVM2,
      content : ["엔쓰리엔은 구조화된 데이터를 수집한 실제 지도 위에서 이종 데이터(엔쓰리엔은 데이터의 상관관계/연관관계에 따라\n각 데이터들의 계층을 정리한 후, 화면을 Drill-down/Role-up 함으로써 계층간 이동을 쉽게 할 수 있도록 구성합니다."],
    },
    {
      title : 'DIGITAL TIME-SPACE CREATION',
      img : DV3,
      m_img2 : DVM3,
      content : ["엔쓰리엔은 Physical Location에 기반한 Real-time Data를 Zoom UX를 통해 담아내며,\nMap 상에서 특정 시간대별 Status를 제공해 입체적 조망이 가능한 Time-space를 만듭니다."]
    }
  ]
}

export default data;