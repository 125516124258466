import { useRef } from 'react';
import { styled } from 'styled-components';
import { Tech1M, Tech2M, Tech3M, Tech4M, Tech5M, Tech6M } from '../assets';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay} from 'swiper/modules';
import 'swiper/css';

const S = {
  Sticky : styled.section`
    width : 100vw;
    height : 400vh;
    position : relative;
  `,
  Tech : styled.section`
    width : 100vw;
    height : 100vh;
    font-family : 'GosanjaOTF';

    position : sticky;
    top : 0;
    left : 0;
    z-index : 1;

    display : flex;
    justify-content : center;
    align-items : center;
    flex-direction : column;

    background : #171717;
    overflow : hidden;
  `,
  Title : styled.h2`
    color : #ffffff;
    font-size : 26px;
    font-weight : 400;
    letter-spacing : -0.01em;
    line-height : 150%;

  `,
  Image : styled.img`
    width : 50vw;
    height : auto;
    object-fit : contain;
  `,
  Item : styled.section`
    width : calc(100vw - 100px);
    overflow : hidden;

    margin-top : 30px;

    & .swiper-wrapper {
      display : flex;
    }

  `,
  Slide : styled.div`
    font-family : 'Pretendard';
  `,
  Slide_title : styled.div`
    font-size : 24px;
    font-weight : 700;
    line-height : 163%;
    letter-spacing : -0.01em;
    color : #ffffff;
    margin-top : 40px;
  `,
  Slide_cont : styled.div`
    font-size : 16px;
    font-weight : 500;
    line-height : 150%;
    letter-spacing : -0.01em;
    color : #929292;
    margin-top : 12px;
    word-break : keep-all;
  `
}
const Tech_M = () => {

  const tmp = useRef(null);

  const data = [
    {
      img : Tech1M,
      title : 'HPC 하드웨어',
      content : "모델을 구현, 가동하기 위한 물리적인 Accelerator Server"
    },
    {
      img : Tech2M,
      title : "GPU CLOUD Infra",
      content : "AI 서버 퍼실리티(영어) 구축 및 다수의 유저/고객이 접근 가능할 수 있도록 하는 플랫폼"
    },
    {
      img : Tech3M,
      title : "LLM Modeling",
      content : "서비스의 기반이 되는 범용성 파운데이션 모델"
    },
    {
      img : Tech4M,
      title : "MLOps",
      content : "파운데이션 모델에서 데이터관리, 파인튜닝, 서비스 운영 등을 여러 유저/고객이 할 수 있게 하는 협업 체계"
    },
    {
      img : Tech5M,
      title : "AI Device",
      content : "파운데이션 모델을 기반으로 개발한 B2B 혹은 B2C 제품"
    },
    {
      img : Tech6M,
      title : "AI Service",
      content : "기계 학습, 피드백 등 AI를 기업/고객 특화로 잘 사용할 수 있게 leverage해주는 서비스"
    },
  ]

  return (
    <S.Sticky ref={tmp}>
      <S.Tech>
        <S.Title>N3N<br/>Future Now<br/>Technology</S.Title>

        <S.Item>
          <Swiper
            modules={[Autoplay]}
            direction={"horizontal"}
            spaceBetween={0}
            slidesPerView={1}
            autoplay ={{
              delay : 2500,
              disableOnInteraction: false
            }}  
          > 
            {data.map((item, key) => (
              <SwiperSlide key={key}>
                <S.Slide>
                  <S.Image src={item.img} alt="image"/>
                  <S.Slide_title>{item.title}</S.Slide_title>
                  <S.Slide_cont>{item.content}</S.Slide_cont>
                </S.Slide>
              </SwiperSlide>
            ))}
          </Swiper>
        </S.Item>
      </S.Tech>
    </S.Sticky>
  )
}

export default Tech_M;