import { useEffect, useState } from 'react';
import {CompanyM, CompanyW} from '.';

const Company = () => {

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);

  useEffect(() => {
    window.addEventListener('resize', setIsMobile(window.innerWidth < 1024));

    return () => window.removeEventListener('resize', setIsMobile(window.innerWidth < 1024))
  }, [])

  return (
    <>
      {isMobile ? <CompanyM/> : <CompanyW/>}
    </>
  )
}

export default Company;