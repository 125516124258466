import { styled } from 'styled-components';
import { logo_ver } from '../assets';
import { useEffect, useState } from 'react';

const S = {
  Footer : styled.footer`
    width : 100vw;
    height : 324px;
    background : #000000;
    text-align : start;
    box-sizing : border-box;
  `,
  Logo : styled.img`
    width : 52px;
    height : 52px;
    filter: brightness(0) invert(1);
    margin-bottom : 28px;
  `,
  Content : styled.p`
    color : #808991;
    font-size : 13px;
    line-height : 18.8px;
    margin-bottom : 16px;
  `,
  Content2 : styled.p`
    font-size : 12px;
    font-weight : bold;
    color : #262C31;
  `,
  Content3 : styled.p`
    font-size : 13px;
    line-height : 18.8px;
    color : #B8BFC4;
  `,
  M_footer : styled.section`
    width : 100%;
    height : 100%;
    background : #171717;

    padding : 40px 20px;
    box-sizing : border-box;
  `,
  M_content : styled.div`
    color : #909090;
    font-size : 13px;
  `
}

const Footer = () => {
  
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);

  useEffect(() => {
    window.addEventListener('resize', setIsMobile(window.innerWidth < 1024));

    return () => window.removeEventListener('resize', setIsMobile(window.innerWidth < 1024))
  }, [])


  return(
    <S.Footer>
      {isMobile ? 
        <S.M_footer>
          <S.Logo src={logo_ver} alt="logo"/>
          <S.M_content>Copyright ⓒ 2017-2023 N3N All Rights Reserved.</S.M_content>
          <S.Content>
            서울특별시 강남구 신사동 524-3 엔쓰리엔(주)<br/>
            Tel : 02-761-5805 <br/> Fax : 02-554-5803
          </S.Content>
          <S.Content2>
            이용약관&emsp;&emsp;개인정보처리방침
          </S.Content2>
          
        </S.M_footer>
        :
        <section style={{'paddingLeft' : '400px', 'paddingTop' : '60px'}}>
          <S.Logo src={logo_ver} alt="logo"/>
          <S.Content>
            (주)엔쓰리엔<br/>서울특별시 강남구 강남대로 162길 41-18(신사동) 엔쓰리엔(주)<br/>
            Tel. 02-761-5805 | Fax. 02-554-5803 | Email. business@n3n.co.kr
          </S.Content>
          <S.Content2>
            이용약관&emsp;&emsp;개인정보처리방침
          </S.Content2>
          <S.Content3>Copyright ⓒ 2017-2023 N3N All Rights Reserved.</S.Content3>
        </section>
      }
    </S.Footer>
  )
}

export default Footer;